import React, { useEffect, useState } from "react";
import {
  Pagination,
  Avatar,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownTrigger,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalContent,
} from "@nextui-org/react";

import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  DeleteSingleAttribute
} from "../../controllers/strapiController.js";
import { getDocument, patchData } from "../../controllers/vectorDB.js";

import { errorHandler } from "../../utils/errorhandler.js";
import Icon from "../../components/Tag/Icon.jsx";
import HelixLoader from "../../components/Loader/HelixLoader.jsx";
import { removePrefix } from "../../utils/default.js";
import {
  customizeTableCell,
  groupingCustomAttributes,
} from "../../utils/customAttributes.js";
import ComponentGroup from "./ComponentGroup.jsx";

const Colors = ["secondary", "success", "warning", "danger", "primary"];
const numColors = Colors.length;

export default function Tables({
  toggleProductManagement,
  res,
  sortType,
  filterParent,
  sortOrder,
  filterOwner,
  filterTypeOwner,
  columns,
  org,
  deletePerm,
  search,
  isOwner,
  partData,
  filterArr,
  template,
  grouping,
}) {
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [parts, setParts] = useState([]);
  const [group, setGroup] = useState(null);
  const [groupType, setGroupType] = useState(null);
  const [partsComplete, setPartsComplete] = useState([]);
  const [partsData, setPartsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(new Set([]));
  const [assignedPart, setAssignedPart] = useState(new Set([]));
  const [assignedDetail, setAssignedDetail] = useState({ id: "", owner: "" });
  const [deleteInput, setDeleteInput] = useState("");
  const [tickets, setTickets] = useState([]);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [deletePart, setDeletePart] = useState(null);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const deleteAllParts = async () => {
    const deleteIdArray = Array.from(deleteId);
    try {
      const responses = await Promise.all(
        deleteIdArray.map((id) => DeleteSingleAttribute("parts", id))
      );
    } catch (error) {
      console.error("Error deleting parts:", error);
    }
  };

  const reassignTickets = async () => {
    if (assignedDetail.owner === "") {
      return;
    }
    const formData = {
      owner: Number(assignedDetail.owner),
      part: assignedDetail.id,
    };
    try {
      const data = await Promise.all(
        tickets.map((ticket) => patchData(formData, ticket.payload.id, org))
      );
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };

  const handleDelete = async () => {
    await deleteAllParts();
    await reassignTickets();
    window.location.reload();
  };

  const handleTickets = async () => {
    const deleteIdArray = Array.from(deleteId);
    const responses = await Promise.all(
      deleteIdArray.map((id) => getDocument("part", id, org))
    );
    const allTickets = responses.flat();
    setTickets(allTickets);
  };

  const openDeleteModal = (part) => {
    let products = 0;

    parts?.forEach((item) => {
      if (item?.type === "Product") {
        products = products + 1;
      }
    });

    if (part?.attributes?.Type === "Product" && products === 1) {
      toast("Cannot delete this product.", {
        duration: 4000,
        position: "bottom-right",
      });
    } else {
      setDeletePart(String(part.id));
      setIsOpen(true);
    }
  };

  const rowsPerPage = 13;
  const pages = Math.ceil(parts.length / rowsPerPage);

  const handlePage = (page) => {
    setTableData([]);
    let data = [];
    if (page === pages) {
      for (let i = (page - 1) * rowsPerPage; i < parts.length; i++) {
        data.push(parts[i]);
      }
    } else {
      for (let i = (page - 1) * rowsPerPage; i < page * rowsPerPage; i++) {
        data.push(parts[i]);
      }
    }
    setTableData(data);
    setPage(page);
  };

  useEffect(() => {
    async function fetchDocument() {
      try {
        const issData = await getDocument("type", "issue", org);
        const tickData = await getDocument("type", "ticket", org);
        if (issData && tickData) {
          setPartsData(partData);
          setPartsComplete(
            partData?.map((part, index) => ({
              key: index.toString(),
              id: part?.id,
              type: part?.attributes?.Type,
              name: part?.attributes?.Name,
              owner: part?.attributes?.users_permissions_user?.data?.id,
              stage: part?.attributes?.Stage,
              part: part?.attributes?.latest_part,
              totaltickets: tickData.filter(
                (tick) => String(tick?.payload?.part) === String(part?.id)
              ),
              metadata: part?.attributes?.metadata,
              description: part?.attributes?.Description,
              tags: part?.attributes?.Tags,
              totalissues: issData.filter(
                (tick) => String(tick?.payload?.part) === String(part?.id)
              ),
              delete: part,
              parent: part?.attributes?.ParentPath,
            }))
          );
        }
        setLoader(false);
      } catch (error) {
        errorHandler(navigate, error);
      } finally {
        setLoader(false);
      }
    }
    if (partData.length > 0) {
      fetchDocument();
    }
  }, [partData]);

  useEffect(() => {
    if (deletePart === null) {
      return;
    }
    const handleOpenDeleteTag = () => {
      let arrdel = [];
      arrdel.push(deletePart);
      partsData.map((item) => {
        const arr = item?.attributes?.ParentPath.split("/");
        if (arr.includes(deletePart)) {
          arrdel.push(String(item?.id));
        }
      });
      setDeleteId(new Set(arrdel));
    };
    handleOpenDeleteTag();
  }, [deletePart]);

  useEffect(() => {
    setAssignedDetail({ id: "", owner: "" });
    setAssignedPart(new Set([]));
    setDeleteInput("");
  }, [isOpen]);

  useEffect(() => {
    if (deleteId) {
      handleTickets();
    }
  }, [deleteId]);
  useEffect(() => {
    if (
      Array.from(grouping).length > 0 &&
      Array.from(grouping)[0] !== "None" &&
      Array.from(grouping)[0].startsWith("metadata_")
    ) {
      const finalGroup = {};
      parts.map((item) => {
        groupingCustomAttributes(
          Array.from(grouping)[0],
          template,
          item.metadata,
          finalGroup,
          item,
          setGroupType
        );
      });
      setGroup(finalGroup);
    }
  }, [grouping]);
  useEffect(() => {
    if (partsComplete.length > 0) {
      let filteredParts = partsComplete;
      if (sortType.length > 0) {
        filteredParts = filteredParts.filter((item) =>
          sortType.includes(item.type)
        );
      }
      if (filterParent.length > 0) {
        let arr = [];
        for (let i = 0; i < filteredParts.length; i++) {
          const set1 = new Set(filteredParts[i].parent.split("/"));
          if (filterParent.some((element) => set1.has(element))) {
            arr.push(filteredParts[i]);
          }
        }
        filteredParts = arr;
      }

      if (isOwner) {
        filteredParts = filteredParts.filter(
          (item) => String(item.owner) === String(userDataId)
        );
      }
      if (search !== "") {
        filteredParts = filteredParts.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        );
      }
      if (filterTypeOwner === "Any of") {
        if (filterOwner.length > 0) {
          filteredParts = filteredParts.filter((item) =>
            filterOwner.includes(String(item.owner))
          );
        }
      }
      if (filterTypeOwner === "None of") {
        if (filterOwner.length > 0) {
          filteredParts = filteredParts.filter(
            (item) => !filterOwner.includes(String(item.owner))
          );
        }
      }
      filterArr.map((item) => {
        if (item?.val?.length > 0) {
          filteredParts = filteredParts.filter((t) => {
            if (t.metadata && Array.isArray(t.metadata)) {
              const find = t.metadata.find(
                (field) => String(field.id) === String(item.id)
              );
              if (find) {
                return find?.val?.some((m) => item.val.includes(m));
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }
      });
      setParts(filteredParts);
    }
  }, [
    sortType,
    partsComplete,
    filterParent,
    filterOwner,
    filterTypeOwner,
    search,
    isOwner,
    filterArr,
  ]);

  useEffect(() => {
    const handlePageChange = () => {
      setTableData([]);
      let data = [];
      if (page === pages) {
        for (let i = (page - 1) * rowsPerPage; i < parts.length; i++) {
          data.push(parts[i]);
        }
      } else {
        for (let i = (page - 1) * rowsPerPage; i < page * rowsPerPage; i++) {
          data.push(parts[i]);
        }
      }
      setTableData(data);
    };
    if (parts.length > 0) {
      handlePageChange();
    }
  }, [parts]);
  return (
    <>
      <Toaster />
      {loader ? (
        <div className="flex flex-col items-center justify-center mt-52">
          <HelixLoader />
        </div>
      ) : parts.length > 0 ? (
        <>
          <Modal
            size="md"
            isOpen={isOpen}
            onOpenChange={() => setIsOpen(false)}
          >
            <ModalContent>
              <ModalHeader>Delete Part </ModalHeader>
              <ModalBody className="flex flex-col ">
                {tickets?.length > 0 &&
                  partsData.filter(
                    (item) => !Array.from(deleteId)?.includes(String(item?.id))
                  ).length > 0 && (
                    <div className="flex gap-2">
                      <div className=" text-xs">
                        Select part to reassign tickets and issues
                      </div>
                      <Dropdown>
                        <DropdownTrigger>
                          <Button size="sm">
                            {Array.from(assignedPart).length === 0
                              ? "Select Part"
                              : Array.from(assignedPart)[0]}
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          variant="flat"
                          disallowEmptySelection
                          selectionMode="single"
                          selectedKeys={assignedPart}
                          onSelectionChange={setAssignedPart}
                        >
                          {partsData
                            .filter(
                              (item) =>
                                !Array.from(deleteId)?.includes(
                                  String(item?.id)
                                )
                            )
                            .map((item) => (
                              <DropdownItem
                                key={item?.attributes?.Name}
                                onClick={() =>
                                  setAssignedDetail({
                                    id: String(item?.id),
                                    owner: String(
                                      item?.attributes?.users_permissions_user
                                        ?.data?.id
                                    ),
                                  })
                                }
                              >
                                {item?.attributes?.Name}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                <div className=" text-xs items-center gap-4 justify-center flex flex-col">
                  {partsData.filter(
                    (item) => !Array.from(deleteId)?.includes(String(item?.id))
                  ).length > 0
                    ? `Write "DELETE" to Permanently delete the part`
                    : `Cannot delete this part`}
                  <Input
                    disabled={
                      !(
                        partsData.filter(
                          (item) =>
                            !Array.from(deleteId)?.includes(String(item?.id))
                        ).length > 0
                      )
                    }
                    value={deleteInput}
                    onValueChange={setDeleteInput}
                    color="danger"
                    className="w-fit"
                  />
                </div>
                <div className=" flex justify-end items-end right-0">
                  <Button
                    onClick={() => handleDelete()}
                    disabled={
                      (tickets?.length === 0
                        ? false
                        : Array.from(assignedPart).length === 0) ||
                      deleteInput !== "DELETE"
                    }
                    color="danger"
                  >
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
          {Array.from(grouping)[0] === "None" ||
          Array.from(grouping).length === 0 ? (
            <Table removeWrapper className="mt-10 px-8 ">
              <TableHeader columns={columns}>
                {(column) => (
                  <TableColumn key={column.key} className="text-xs">
                    {column.key.startsWith("metadata_")
                      ? template?.find(
                          (it) =>
                            it.id === removePrefix(column.key, "metadata_")
                        )?.name ?? ""
                      : column.label}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody items={tableData}>
                {(item) => (
                  <TableRow
                    className=" cursor-pointer hover:bg-gray-100 dark:hover:bg-[#2D2D32] text-xs border-b border-divider"
                    key={item.key}
                    onClick={() => toggleProductManagement(item)}
                  >
                    {columns.map((column) => {
                      if (column.key.startsWith("metadata_")) {
                        return customizeTableCell(
                          column.key,
                          template,
                          item.metadata,
                          res
                        );
                      } else {
                        return (
                          <TableCell className="text-xs" key={column.key}>
                            {column.key === "part" ? (
                              <Chip
                                className=""
                                color={
                                  item["type"] === "Product"
                                    ? "primary"
                                    : item["type"] === "Capability"
                                    ? "secondary"
                                    : item["type"] === "Enhancement"
                                    ? "warning"
                                    : item["type"] === "Feature"
                                    ? "success"
                                    : "danger"
                                }
                                variant="flat"
                                size="sm"
                              >
                                {item["type"] === "Product"
                                  ? "PRO"
                                  : item["type"] === "Capability"
                                  ? "CAP"
                                  : item["type"] === "Enhancement"
                                  ? "ENH"
                                  : item["type"] === "Feature"
                                  ? "FEA"
                                  : "RUN"}
                                -{item[column.key]}
                              </Chip>
                            ) : column.key === "stage" ? (
                              <p>{item.stage ? item.stage : "-"}</p>
                            ) : column.key === "delete" ? (
                              deletePerm === false ? (
                                <></>
                              ) : (
                                <Dropdown>
                                  <DropdownTrigger>
                                    <Button
                                      size="sm"
                                      color="danger"
                                      variant="light"
                                      isIconOnly
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-4"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                        />
                                      </svg>
                                    </Button>
                                  </DropdownTrigger>
                                  <DropdownMenu size="sm">
                                    <DropdownItem
                                      onClick={() =>
                                        openDeleteModal(item["delete"])
                                      }
                                      variant="flat"
                                      color="danger"
                                    >
                                      Delete Permanently
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              )
                            ) : column.key === "owner" ? (
                              <Avatar
                                showFallback
                                color={
                                  Colors[Number(item[column.key]) % numColors]
                                }
                                name={
                                  res[String(item[column.key])]?.FirstName
                                    ? res[
                                      String(item[column.key])
                                      ]?.FirstName[0].toUpperCase()
                                    : res[
                                      String(item[column.key])
                                      ]?.UserName[0].toUpperCase()
                                }
                                size="sm"
                                radius="sm"
                                src={
                                  process.env.REACT_APP_STAGE === "Dev"
                                    ? `${
                                        process.env.REACT_APP_STRAPI_IP_ADDRESS
                                      }${res[String(item[column.key])]?.ProfilePicture}`
                                    : `${res[String(item[column.key])]?.ProfilePicture}`
                                }
                              />
                            ) : column.key === "totaltickets" ||
                              column.key === "totalissues" ? (
                              item[column.key]?.length
                            ) : (
                              item[column.key]
                            )}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : Array.from(grouping)[0].startsWith("metadata_") ? (
            <ComponentGroup
              template={template}
              toggleProductManagement={toggleProductManagement}
              columns={columns}
              group={group}
              type={groupType}
            />
          ) : (
            <></>
          )}
          {parts.length > rowsPerPage && Array.from(grouping)[0] === "None" && (
            <div
              className={`flex my-8 text-xs w-full justify-center  ${
                tableData.length < 15 ? "" : ""
              }`}
            >
              <Pagination
                isCompact
                showControls
                showShadow
                color="danger"
                page={page}
                total={pages}
                onChange={(page) => handlePage(page)}
              />
            </div>
          )}
        </>
      ) : (
        <p className="flex flex-col mt-60 text-xs gap-2  justify-center items-center  text-slate-400">
          <Icon />
          Your organization has no components
        </p>
      )}
    </>
  );
}
