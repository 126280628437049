import React, { useState, useEffect } from "react";
import {
  Button,
  Image,
  Input,
  Progress,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import {
  CreateData,
  UpdateOneUserData,
  getAllData,
} from "../../controllers/strapiController";
import { createDefault } from "../../utils/defaultFunction";
import { defaultConfiguration } from "../../utils/default";
import { connect } from "react-redux";

export default function Onboarding() {
  const base = `${process.env.REACT_APP_IP_ADDRESS}`;
  const navigate = useNavigate();
  const [orgName, setOrgName] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [orgUrl, setOrgUrl] = useState("");
  const userLogged = JSON.parse(sessionStorage.getItem("userData"));
  const userId = userLogged && userLogged.user.id;
  const [furl, setFUrl] = useState(null);
  const [errors, setErrors] = useState({
    orgName: "",
    orgUrl: "",
  });
  const [loading, setLoading] = useState(-1);
  const sanitizeOrgName = (name) => {
    return name
      .replace(/[^a-zA-Z0-9-_ ]/g, "");
  };
  

  const handleOrgNameChange = (value) => {
    const sanitizedValue = sanitizeOrgName(value);
    setOrgName(sanitizedValue);
    const url = generateURL(sanitizedValue);
    setOrgUrl(url);
  };

  useEffect(() => {
    const handleOrg = async () => {
      const resp = await getAllData("organizations");
      let arr = [];
      resp?.data?.map((item) => {
        arr.push(item.attributes.URL);
      });
      setOrganizations(arr);
    };
    handleOrg();
  }, []);

  const handleCreateOrg = async () => {
    if (!validateFields()) {
      return;
    }

    const name = generateURL(orgName);
    const url = generateURL(orgName);
    const tenant = orgName;

    if (!organizations.includes(url)) {
      const update = {
        OrgRoute: `/${name}`,
      };
      const newOrg = {
        Name: name,
        URL: url,
        Tenant: tenant,
        admin_user : {
          disconnect : [],
          connect : [
            {
              id : userId,
              position : {
                end : true
              }
            }
          ]
        },
        users: {
          disconnect: [],
          connect: [
            {
              id: userId,
              position: {
                end: true,
              },
            },
          ],
        },
        configuration : defaultConfiguration,
        ticket_latest: 5,
        issue_latest: 5,
        Product_latest: 1,
        Capability_latest: 0,
        Feature_latest: 0,
        Enhancement_latest: 0,
        Runnable_latest: 0,
        inbox_metadata : [],
        customer_metadata : [],
        account_metadata : [],
        component_metadata : [],
        issue_metadata : [],
        ticket_metadata : []
      };
      try {
        await UpdateOneUserData("users", userId, update);
        await CreateData("organizations", newOrg);
        await createDefault(name, userId, setLoading);
        setFUrl(url);
      } catch (error) {}
    }
  };

  const validateFields = () => {
    let valid = true;
    const newErrors = {
      orgName: "",
      orgUrl: ""
    };

    if (orgName.trim() === "") {
      newErrors.orgName = "Organization name is required";
      valid = false;
    }

    if (orgUrl.trim() === "") {
      newErrors.orgUrl = "URL is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const generateURL = (name) => {
    const lowercaseName = name.toLowerCase().replace(/\s+/g, "-");
    return lowercaseName;
  };




  useEffect(() => {
    if (loading === 100 && furl) {
      navigate(`/${furl}`);
    }
  }, [loading]);

  const renderForm = () => {
    return (
      <div className="mx-auto min-h-[590px]  w-full max-w-[450px] h-screen flex flex-col justify-center ">
        <div className="flex  ">
          <Image
            width={50}
            alt="NextUI hero Image"
            src="https://avatars.githubusercontent.com/u/170258109?s=200&v=4"
          />
        </div>

        <p className="mt-8">Name of your Organization</p>
                <Input
                  placeholder="your-org-name"
                  value={orgName}
                  className="text-default-400"
                  onChange={(e) => handleOrgNameChange(e.target.value)}
                />
        {errors.orgName && <p className="text-red-500">{errors.orgName}</p>}

        <p className="mt-4">URL</p>
        <Input
          color={
            errors.orgUrl
              ? "danger"
              : organizations.includes(orgUrl)
              ? "danger"
              : "default"
          }
          type="text"
          name="URL"
          className="text-xs mt-6"
          labelPlacement="outside"
          value={orgUrl}
          placeholder="URL"
          readOnly
          startContent={
            <div className="pointer-events-none flex items-center">
              <span className="text-default-400 text-small">{base}</span>
            </div>
          }
        />
        {errors.orgUrl && <p className="text-red-500">{errors.orgUrl}</p>}

        

        <div className="flex justify-center mt-10">
          <Button
            disabled={
              organizations.includes(orgUrl) ||
              errors.orgName !== "" ||
              errors.orgUrl !== "" 
            }
            radius="sm"
            onClick={handleCreateOrg}
            className="w-96 gap-2 lt-500 text-white bg-black hover:bg-gray-700 hover:text-white"
          >
            Get Started
            <lord-icon
              src="https://cdn.lordicon.com/vduvxizq.json"
              trigger="hover"
              target="#CreateAccount"
              colors="primary:#ffffff"
              style={{
                width: "18px",
                height: "20px",
              }}
            />
          </Button>
        </div>
      </div>
    );
  };

  if (loading >= 0) {
    return (
      <div className="mx-auto min-h-[590px] items-center w-full max-w-[450px] h-screen flex flex-col justify-center">
        <Progress value={loading} showValueLabel={false} />
      </div>
    );
  } else {
    return <>{renderForm()}</>;
  }
}
