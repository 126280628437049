import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  handleReload = () => {
    this.setState({ hasError: false }, () => {
      window.location.href = '/'; // This will reload the page and navigate to the homepage
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-screen flex flex-col items-center justify-center bg-gray-100">
          <h1 className="text-3xl font-bold mb-4">Uh-oh, something went wrong!</h1>
          <p className="mb-4">We apologize for the inconvenience. Please try again later.</p>
          <button onClick={this.handleReload} className="text-blue-500 underline">
            Go back to homepage
          </button>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
