
export const signInRoute = async(email,pass) =>{
  try {
    const response = await fetch(`${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/auth/sign-in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password : pass }),
    });

    const data = await response.json();

    if (!response.ok) {
      return {error : data?.message || 'Something went wrong'}
    }

    // Handle successful response
    sessionStorage.setItem("userData", JSON.stringify(data?.loginInfo));
    return data;
    // Redirect or perform other actions
  } catch (err) {
    return {error : err?.message || 'Something went wrong'}
  }
}

export const signUpRoute = async(email,pass) =>{
  try {
    const response = await fetch(`${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/auth/sign-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password : pass }),
    });

    const data = await response.json();

    if (!response.ok) {
      return {error : data?.message || 'Something went wrong'}
    }

    // Handle successful response
    sessionStorage.setItem("userData", JSON.stringify(data?.loginInfo));
    return data;
    // Redirect or perform other actions
  } catch (err) {
    return {error : err?.message || 'Something went wrong'}
  }
}

export const signInLlamauth = async(email) =>{
  try {
    const response = await fetch(`${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/auth/llama`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email}),
    });

    const data = await response.json();

    if (!response.ok) {
      return {error : data?.message || 'Something went wrong'}
    }

    // Handle successful response
    sessionStorage.setItem("userData", JSON.stringify(data?.loginInfo));
    return data;
    // Redirect or perform other actions
  } catch (err) {
    return {error : err?.message || 'Something went wrong'}
  }
}

export const sendCreateAccountOTP = async (payload) => {

  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send-create-account-otp`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = await response.json();

  return data;
};
