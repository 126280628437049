// STRING LIMIT FUNCTION
import pluralize from "pluralize";
export const doPluralize = (text) => {
  // let temp = text;
  if (text === pluralize(text)) {
    return `${text}`;
  }
  return pluralize(text);
};

// STRING LIMIT FUNCTION

// Create an entry
export const CreateData = async (MetricName, newData) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  try {
    const response = await fetch(URL, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(newData),
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });

    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error(errorData?.message || "Error fetching data");
      error.status = response.status;
      error.data = errorData;
      throw error;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in CreateData:", error);
    throw error;
  }
};

// UPDATE an entry in a collection
export const UpdateData = async (MetricName, id, newData) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}/${id}`;
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "PUT",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = await response.json();
  return data;
};

// Get a particular entry in a collection
export const getOneData = async (MetricName, id, fields) => {
  let field = fields ? fields : [];
  const payload = {fields : field}
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}/${id}/get-one`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json"
    },
    body : JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const UpdateOneUserData = async (MetricName, id, newData) => {
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(
    `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}/${id}/user`,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(newData),
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let updatedData = await response.json();
  return updatedData;
};

export const getAllData = async (MetricName) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      // Authorization: `Bearer ${jwt}`
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = response.json();
  return data;
};

// Delete an entry in a collection
export const DeleteSingleAttribute = async (MetricName, id) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}/${id}`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

// FILE UPLOAD TO STRAPI
export const uploadFile = async (file) => {
  const URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/upload`;
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }
  const response = await fetch(URL, {
    method: "POST",
    body: file,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  let data = response.json();

  return data;
};


export const getFilteredData = async (MetricName, filterKey, filterValue) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}/filter-onedata`;
  const payload = {filterKey , filterValue}
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body : JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(
      errorData?.message || "Error fetching filtered data"
    );
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getFilteredByTwoData = async (
  MetricName,
  filterKey1,
  filterValue1,
  filterKey2,
  filterValue2
) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}/filter-twodata`;
  const payload = {filterKey1,filterValue1,filterKey2,filterValue2};
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body : JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(
      errorData?.message || "Error fetching data with two filters"
    );
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getFilteredByTwoRelation = async (
  MetricName,
  filterKey1,
  filterValue1,
  filterKey2,
  filterValue2
) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}/filter-tworelation`;
  const payload = {filterKey1,filterValue1,filterKey2,filterValue2};
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body : JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(
      errorData?.message || "Error fetching data with two relations"
    );
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getFilteredBySingleRelation = async (
  MetricName,
  filterKey1,
  filterValue1,
  fields,
  populate
) => {
  const payload = {filterKey1,filterValue1,fields : fields ?? [], populate : populate ?? []}
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}/filter-onerelation`;
  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body : JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};

export const getFilteredBySingleRelationSingleData = async (
  MetricName,
  filterKey1,
  filterValue1,
  filterKey2,
  filterValue2
) => {
  const payload = {filterKey1,filterValue1,filterKey2,filterValue2}
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/api/${MetricName}/relation-data`;

  let jwt;

  if (JSON.parse(sessionStorage.getItem("userData"))) {
    jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
  } else {
    jwt = "";
  }

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    body : JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error(errorData?.message || "Error fetching data");
    error.status = response.status;
    error.data = errorData;
    throw error;
  }

  let data = await response.json();
  return data;
};
