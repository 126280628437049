import { EditorProvider } from '@tiptap/react'
import React, { useState } from 'react'
import { extensions, MenuBar, props } from '../../components/markdown/markdown'
import { useSelector } from 'react-redux';
import { selectUserNames } from '../../redux/actions';

const ReleaseMarkdown = ({desc,setDesc,start,setStart}) => {
    
    const [imageIds,setImageIds] = useState([]);
    const userNames = useSelector(selectUserNames);
  return (
    <div className="w-full mb-4 border border-divider rounded-lg h-[600px] overflow-y-auto">
          <EditorProvider
                  editorProps={props("max-h-[560px]")}
                  slotBefore={
                    <MenuBar
                      descriptionContent={desc}
                      imageArray={imageIds}
                      setImageArray={setImageIds}
                      setDescriptionContent={setDesc}
                      initial={start}
                      setInitial={setStart}
                    />
                    
                  }
                  extensions={extensions(userNames)}
                  content={desc}
                ></EditorProvider>
          </div>
  )
}

export default ReleaseMarkdown
