import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { NextUIProvider } from "@nextui-org/react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import ErrorBoundary from "./utils/error";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
  <NextUIProvider>
    <NextThemesProvider attribute="class" defaultTheme="light">
      <HelmetProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </HelmetProvider>
    </NextThemesProvider>
  </NextUIProvider>
  </ErrorBoundary>
);

reportWebVitals();
