import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectOrgData } from "../../redux/actions";
import { DeleteSingleAttribute } from "../../controllers/strapiController";
import { deleteData } from "../../controllers/vectorDB";
import { useNavigate } from "react-router-dom";

const DeleteModal = ({
  isOpen,
  setIsOpen,
  tickets,
  issues,
  blueprints,
  releases,
}) => {
  const orgInfo = useSelector(selectOrgData);
  const navigate = useNavigate();
  const [input, setInput] = useState("");
  const handleDelete = async () => {
    try {
      const issueIds = issues.map((it) => it.payload.id);
      const releaseIds = issues.map((it) => it.payload.id);
      const ticketIds = tickets.map((it) => it.payload.id);
      const blueprintIds = blueprints.map((it) => it.payload.id);
      const partPromises = orgInfo?.parts?.data.map((it) =>
        DeleteSingleAttribute("parts", it.id)
      );
      const tagPromises = orgInfo?.tags?.data.map((it) =>
        DeleteSingleAttribute("tags", it.id)
      );
      const invitationPromises = orgInfo?.invitations?.data.map((it) =>
        DeleteSingleAttribute("invitations", it.id)
      );
      const groupPromises = orgInfo?.groups?.data.map((it) =>
        DeleteSingleAttribute("groups", it.id)
      );
      const taskPromises = orgInfo?.tasks?.data.map((it) =>
        DeleteSingleAttribute("tasks", it.id)
      );
      const sprintPromises = orgInfo?.sprints?.data.map((it) =>
        DeleteSingleAttribute("sprints", it.id)
      );
      const customerPromises = orgInfo?.customers?.data.map((it) =>
        DeleteSingleAttribute("customers", it.id)
      );
      const accountPromises = orgInfo?.accounts?.data.map((it) =>
        DeleteSingleAttribute("accounts", it.id)
      );
      const updatePromises = orgInfo?.updates?.data.map((it) =>
        DeleteSingleAttribute("updates", it.id)
      );
      const widgetPromise = DeleteSingleAttribute(
        "updates",
        orgInfo?.widget?.data?.id
      );
      const vectorDbPromises = [
        ...issueIds,
        ...ticketIds,
        ...releaseIds,
        ...blueprintIds,
      ]?.map((id) => deleteData(id, orgInfo?.attributes?.Name));
      await Promise.all([
        ...updatePromises,
        customerPromises,
        ...accountPromises,
        widgetPromise,
        ...sprintPromises,
        ...taskPromises,
        ...groupPromises,
        ...invitationPromises,
        ...tagPromises,
        ...partPromises,
        ...vectorDbPromises,
      ]);
      await DeleteSingleAttribute("organizations", orgInfo.id);
      navigate("/sign-in");
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setInput('');
  },[isOpen])
  return (
    <Modal backdrop="opaque" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalContent>
        <ModalHeader>Delete Organization</ModalHeader>
        <ModalBody>
          <Input
            placeholder='Type "DELETE" here to delete '
            className="w-full"
            color="danger"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </ModalBody>
        <ModalFooter className="flex justify-end">
          <Button
            onClick={handleDelete}
            disabled={input !== "DELETE"}
            color="danger"
            size="md"
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
