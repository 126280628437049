import React, { useEffect, useState } from "react";
import GeneralHeader from "./Header";
import { Avatar, Button, Card, CardBody } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { selectOrgData, selectTheme, selectUserData } from "../../redux/actions";
import { getDocument } from "../../controllers/vectorDB";
import { Colors, numColors } from "../../utils/constant";
import DeleteModal from "./DeleteModal";

const General = () => {
  const orgInfo = useSelector(selectOrgData);
  const theme = useSelector(selectTheme);
  const [loading, setLoading] = useState(true);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [issues, setIssues] = useState([]);
  const [releases,setReleases] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [deleteOpen,setDeleteOpen] = useState(false);
  const users = useSelector(selectUserData);
  const [blueprints, setBlueprints] = useState([]);
  
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const releaseData = await getDocument(
          "type",
          "release",
          orgInfo?.attributes?.Name
        );
        const tickData = await getDocument(
          "type",
          "ticket",
          orgInfo?.attributes?.Name
        );
        const issData = await getDocument(
          "type",
          "issue",
          orgInfo?.attributes?.Name
        );
        const blue = await getDocument(
          "type",
          "blueprint",
          orgInfo?.attributes?.Name
        );
        setTickets(tickData);
        setIssues(issData);
        setBlueprints(blue);
        setReleases(releaseData);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    if (orgInfo?.id) {
      fetchInfo();
    }
  }, [orgInfo]);
  return (
    <div className="h-full overflow-y-auto flex flex-col w-full">
      <DeleteModal releases={releases} tickets={tickets} issues={issues} blueprints={blueprints} isOpen={deleteOpen} setIsOpen={setDeleteOpen} />
      <GeneralHeader />
      <div className="py-20 flex items-center justify-center w-full overflow-y-auto ">
        <Card shadow="none" className="w-[40%] h-[50vh] border border-divider">
          <CardBody className="p-8">
            <div className="flex items-center gap-4 ">
              <Avatar
                radius="lg"
                size="lg"
                color={Colors[Number(orgInfo?.id) % numColors]}
                showFallback
                name={orgInfo?.attributes?.Tenant.charAt(0)}
              />
              <div className="text-xl">{orgInfo?.attributes?.Tenant}</div>
            </div>
            <div className="flex items-center  pt-8 px-4">
              <div className="flex items-center gap-1 w-[50%]">
                <lord-icon
                  src="https://cdn.lordicon.com/gyblqrqz.json"
                  colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
                <div>Tickets-</div>
                <div>{tickets.length}</div>
              </div>
              <div className="flex items-center gap-1 w-[50%]">
                <lord-icon
                  src="https://cdn.lordicon.com/ygvjgdmk.json"
                  colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
                <div>Issues-</div>
                <div>{issues.length}</div>
              </div>
            </div>
            <div className="flex items-center pt-8 px-4">
              <div className="flex items-center gap-1 w-[50%]">
                <lord-icon
                  src="https://cdn.lordicon.com/kthelypq.json"
                  colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
                <div>Users-</div>
                <div>{users.length}</div>
              </div>
              <div className="flex items-center gap-1 w-[50%]">
                <lord-icon
                  src="https://cdn.lordicon.com/eouimtlu.json"
                  colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
                <div>Components-</div>
                <div>{orgInfo?.attributes?.parts?.data?.length}</div>
              </div>
            </div>
            <div className="flex items-center pt-8 px-4">
              <div className="flex items-center gap-1 w-[50%]">
                <lord-icon
                  src="https://cdn.lordicon.com/lyrrgrsl.json"
                  colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
                <div>Blueprints-</div>
                <div>{blueprints.length}</div>
              </div>
              <div className="flex items-center gap-1 w-[50%]">
                <lord-icon
                  src="https://cdn.lordicon.com/gfgzffjw.json"
                  colors={`primary:${theme === "dark" ? "#ffffff" : "#000000"}`}
                  style={{ width: "20px", height: "20px" }}
                ></lord-icon>
                <div>Tags-</div>
                <div>{orgInfo?.attributes?.tags?.data?.length}</div>
              </div>
            </div>
            { (orgInfo?.attributes?.admin_user?.data?.id === userDataId) && <div className="flex-1 flex items-end justify-end" >
              <Button onClick={() => setDeleteOpen(true)} color="danger" >Delete</Button>
            </div>}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default General;
