import React, { useState, useEffect } from "react";
import {
  getMultipleFiltersSimilar,
  getSingleDocument,
  patchDataInbox,
  postData,
} from "../../controllers/vectorDB";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownTrigger,
  DatePicker,
  Input,
  Button,
  Avatar,
  Divider,
  DropdownSection,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Card,
  CardBody,
  Chip,
  Spinner,
} from "@nextui-org/react";
import {
  DeleteSingleAttribute,
  UpdateData,
  getFilteredBySingleRelation,
  getOneData,
} from "../../controllers/strapiController";

import { IoIosClose } from "react-icons/io";
import { extensions, props, MenuBar } from "../../components/markdown/markdown";
import { EditorProvider } from "@tiptap/react";
import { getLocalTimeZone, today } from "@internationalized/date";
import Tags from "../Tag/tags";
import PartPicker from "./PartPicker";
import { basicIcons, inputClassNames } from "../../utils/default";
import WarningIcon from "../../Images/Icons/WarningIcon";
import ChevronArrow from "../../Images/Icons/ChevronArrow";
import ScredriverWrench from "../../Images/Icons/ScredriverWrench";
import LinkIssues from "./LinkIssues";
import { issuesTicketLink } from "../../utils/linkissues";
import { sendUpdateTickets } from "../../utils/mentions";
import { generateMetadataStructure } from "../../utils/customAttributes";
import AttributesRenderer from "../AttributesRenderer/AttributesRenderer";
const Drawer = ({
  toggleDrawer,
  query,
  dropdownref,
  res,
  divRef,
  modalref,
  part,
  tenant,
  users,
  inputRef,
  userNames,
  inboxThreadId,
  isInbox,
  setIsInbox,
  customAttributeRef
}) => {
  const [descriptionContent, setDescriptionContent] = useState("");
  const [ownerInfo, setOwnerInfo] = useState();
  const [title, setTitle] = useState("");

  const [tags, setTags] = useState([]);
  const [tagsDetail, setTagsDetail] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [selectedReporter, setSelectedReporter] = useState(new Set([]));
  const [search, setSearch] = useState("");
  const [selectedPriority, setSelectedPriority] = useState(new Set());
  const [similarTickets, setSimilarTickets] = useState([]);
  const priorityData = {
    Urgent: {
      color: "text-red-500",
      id: "P1",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="size-5 text-red-500"
        >
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    High: {
      color: "text-yellow-500",
      id: "P2",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="size-5 text-yellow-500"
        >
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    Normal: {
      color: "text-blue-500",
      id: "P3",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="size-5 text-blue-500"
        >
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
    Low: {
      color: "text-gray-500",
      id: "P4",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="size-5 text-gray-500"
        >
          <path d="M3.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0v-4.392l1.657-.348a6.449 6.449 0 0 1 4.271.572 7.948 7.948 0 0 0 5.965.524l2.078-.64A.75.75 0 0 0 18 12.25v-8.5a.75.75 0 0 0-.904-.734l-2.38.501a7.25 7.25 0 0 1-4.186-.363l-.502-.2a8.75 8.75 0 0 0-5.053-.439l-1.475.31V2.75Z" />
        </svg>
      ),
    },
  };
  const selected = Array.from(selectedPriority)[0];
  const selectedData = priorityData[selected] || {};
  const [reporterInfo, setReporterInfo] = useState({
    id: "",
    name: "",
    url: "",
    username: "",
  });
  const [loader, setLoader] = useState(false);
  const [showSimilar, setShowSimilar] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedKeys, setSelectedKeys] = useState(new Set(["tags"]));
  const [totalTickets, setTotalTickets] = useState({
    ticket: 0,
    issue: 0,
    opportunity: 0,
  });
  const [metadata, setMetadata] = useState([]);
  const [issueTemp, setIssueTemp] = useState([]);
  const [ticketTemp, setTicketTemp] = useState([]);
  const [sprints, setSprints] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState(new Set([]));
  const [imageArray, setImageArray] = useState([]);
  const [selectedEstimatedDays, setSelectedEstimatedDays] = useState("");
  const [selectedPart, setSelectedPart] = useState(
    part ? new Set([String(part?.id)]) : new Set([])
  );
  const [selectedIssueIds, setSelectedIssueIds] = useState(new Set([]));
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [start, setStart] = useState(false);
  const [clearEditor, setClearEditor] = useState(0);
  const [type, setType] = useState(new Set([query]));
  const [partData, setPartData] = useState([]);
  const [parsedParts, setParsedParts] = useState({});
  const [parsedSprints, setParsedSprints] = useState({});
  const [filteredSprints, setFilteredSprints] = useState([]);
  const [onHover, setOnHover] = useState("");
  const [sprintNumber, setSprintNumber] = useState("");
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const [toggleSimilar, setToggleSimilar] = useState(false);
  const handleClear = () => {
    setSimilarTickets([]);
    setShowSimilar(false);
    setTitle("");
    setDescriptionContent("");
    setSelectedEstimatedDays("");
    setTags([]);
    setImageArray([]);
    setClearEditor(clearEditor + 1);
    setTagsDetail([]);
    setSelectedPart(new Set([]));
    setSelectedReporter("");
    setReporterInfo({ id: "", name: "", url: "", username: "" });
    setOwnerInfo({ name: "", url: "", id: "", username: "" });
    setSelectedDate("");
    setSelectedPriority(new Set([]));
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const updateReporter = (user) => {
    setReporterInfo({
      username: user.username,
      id: user.id,
      name: user.FirstName,
      url: user.ProfilePicture ? `${user.ProfilePicture.formats.small.url}` : null
    });
  };
  const [selectedSimilar, setSelectedSimilar] = useState(null);

  const handleShow = async () => {
    if (title !== "") {
      try {
        setShowSimilar(true);
        setLoader(true);
        const resp = await getMultipleFiltersSimilar(title, tenant.name);
        if (resp?.result?.length > 0) {
          setSimilarTickets(resp.result);
        } else if (resp === null || !resp.result) {
          setSimilarTickets([]);
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    } else {
      setShowSimilar(false);
    }
  };
  const handleSelectSprint = (id, num) => {
    if (
      Array.from(selectedSprint)[0] === id &&
      (Number(sprintNumber) === Number(num) ||
        (sprintNumber === num && num === "Backlog"))
    ) {
      setSelectedSprint(new Set([]));
      setSprintNumber("");
      return;
    }
    setSelectedSprint(new Set([id]));
    setSprintNumber(num);
  };
  const handleMouseEnter = (id) => {
    setOnHover(id);
  };
  const handleMouseLeave = () => {
    setOnHover("");
  };
  const handleSetProps = async (item) => {
    setSelectedSimilar(item?.latest);
    setSimilarTickets(
      similarTickets?.filter((ticket) => ticket.payload.latest === item.latest)
    );
    const filtered = customTags.filter((option) =>
      item?.tags.includes(option.id)
    );
    await setDescriptionContent(item.description);
    setStart(false);
    setSelectedPart(new Set([item?.part]));
    if (item.assigned) {
      setSelectedReporter(new Set([item.assigned]));
      setReporterInfo({
        username: res[String(item.assigned)].UserName,
        id: item.assigned,
        name: res[String(item.assigned)].FirstName,
        url: res[String(item.assigned)].ProfilePicture ? `${res[String(item.assigned)].ProfilePicture}` : null
      });
    }
    setSelectedPriority(new Set([item.priority]));
    setTags(item.tags);
    setTagsDetail(filtered);
    setSelectedEstimatedDays(Number(item.estimatedays));
  };

  const handleSubmit = async () => {
    let tic, iss, opp;
    let imageIds = [];
    imageArray.map((item) => {
      if (descriptionContent.includes(item.url)) {
        imageIds.push(item);
      }
    });
    const deleteIds = imageArray.filter(
      (item) => !descriptionContent.includes(item.url)
    );

    const link =
      Array.from(type)[0] === "ticket"
        ? { linkedIssues: Array.from(selectedIssueIds) }
        : { linkedTickets: Array.from(selectedIssueIds) };
    const formData = {
      latest:
        Array.from(type)[0] === "ticket"
          ? String(totalTickets.ticket + 1)
          : Array.from(type)[0] === "issue"
          ? String(totalTickets.issue + 1)
          : String(totalTickets.opportunity + 1),
      type: Array.from(type)[0],
      imageIds: imageIds,
      imagePresent: imageIds.length === 0 ? false : true,
      title: title,
      description: descriptionContent,
      owner: String(ownerInfo.id),
      stage: "Queued",
      logs: [
        { operation: "create", by: `${userDataId}`, timestamp: Date.now() },
      ],
      metadata : metadata,
      priority: selectedData.id,
      tags: tags,
      assigned: Array.from(selectedReporter)[0],
      targetclosedate: selectedDate ? selectedDate : "",
      estimatedays: String(selectedEstimatedDays),
      created_by: `${userDataId}`,
      part: selectedPart.size === 0 ? "" : Array.from(selectedPart)[0],
      sprint:
        Array.from(selectedSprint).length === 0
          ? null
          : String(Array.from(selectedSprint)[0]),
      currentSprint: sprintNumber === "" ? null : String(sprintNumber),
      ...link,
    };
    try {
      if (Array.from(type)[0] === "ticket") {
        tic = totalTickets.ticket + 1;
      } else if (Array.from(type)[0] === "issue") {
        iss = totalTickets.issue + 1;
      } else {
        return;
      }
      await UpdateData("organizations", tenant.id, {
        ticket_latest: tic,
        issue_latest: iss,
      });
      const idTick = Date.now();
      await issuesTicketLink(
        Array.from(type)[0],
        Array.from(selectedIssueIds),
        idTick,
        tenant.name
      );
      await postData(idTick, formData, tenant.name);
      await sendUpdateTickets(
        ownerInfo.id,
        Array.from(selectedReporter)[0],
        descriptionContent,
        userDataId,
        Array.from(type)[0],
        "create",
        users,
        res,
        Array.from(type)[0] === "ticket"
          ? "tickets"
          : `issues/${totalTickets.issue + 1}`,
        Array.from(selectedPart)[0],
        Array.from(type)[0] === "ticket"
          ? totalTickets.ticket + 1
          : totalTickets.issue + 1,
        tenant.id
      );
      if (inboxThreadId) {
        const thread = await getSingleDocument(inboxThreadId, tenant.name);
        const ticketArr = thread[0].payload.tickets;
        ticketArr.push({
          id: idTick,
          timestamp: Date.now(),
          title: title,
          latest: totalTickets.ticket + 1,
        });
        await patchDataInbox(
          { tickets: ticketArr },
          inboxThreadId,
          tenant.name
        );
      }
      const promises = deleteIds.map((id) =>
        DeleteSingleAttribute("upload/files", Number(id.id))
      );
      await Promise.all(promises);
      if (inboxThreadId) {
        setIsInbox(!isInbox);
        toggleDrawer();
      } else {
        window.location.reload();
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (sprints.length > 0) {
      const filtered = sprints.filter((option) =>
        option.attributes.Title.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredSprints(filtered);
    }
  }, [search, sprints]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch organization data
        const orgData = await getOneData("organizations", tenant.id, [
          "ticket_latest",
          "issue_latest",
          "opportunity_latest",
          "ticket_metadata",
          "issue_metadata",
        ]);

        if (orgData) {
          setTotalTickets({
            ticket: orgData.data.attributes.ticket_latest,
            issue: orgData.data.attributes.issue_latest,
            opportunity: orgData.data.attributes.opportunity_latest,
          });
          setIssueTemp(orgData.data.attributes.issue_metadata ?? []);
          setTicketTemp(orgData.data.attributes.ticket_metadata ?? []);
        }

        // Fetch parts, sprints, and tags data
        const [partsResp, sprintsResp, tagsResp] = await Promise.all([
          getFilteredBySingleRelation("parts", "organization", tenant.id),
          getFilteredBySingleRelation("sprints", "organization", tenant.id),
          getFilteredBySingleRelation("tags", "organization", tenant.id),
        ]);

        // Set the fetched data
        setPartData(partsResp.data);
        setSprints(sprintsResp.data);
        setCustomTags(tagsResp.data);

        // Parse parts data
        if (partsResp.data.length > 0) {
          const convertedParts = {};
          partsResp.data.forEach((item) => {
            convertedParts[String(item.id)] = {
              Name: item.attributes?.Name,
              Type: item.attributes?.Type,
              ParentPath: item.attributes?.ParentPath,
              Owner: item.attributes?.users_permissions_user?.data?.id,
            };
          });
          setParsedParts(convertedParts);
        }

        // Parse sprints data
        if (sprintsResp.data.length > 0) {
          const convertedSprints = {};
          sprintsResp.data.forEach((item) => {
            convertedSprints[item.id] = {
              Name: item.attributes?.Title,
            };
          });
          setParsedSprints(convertedSprints);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    if (tenant?.id) {
      fetchData();
    }
  }, [tenant]);

  useEffect(() => {
    if (Array.from(selectedPart).length > 0 && parsedParts) {
      const idOwner = String(parsedParts[String(Array.from(selectedPart)[0])]?.Owner);
      setOwnerInfo((prev) => ({
        ...prev,
        id: idOwner,
        username: res[String(idOwner)]?.UserName,
        name: res[String(idOwner)]?.FirstName,
        url: `${res[String(idOwner)]?.ProfilePicture}`
      }));
    }
  }, [selectedPart, parsedParts]);

  useEffect(() => {
    setSelectedIssueIds(new Set([]));
    if (Array.from(type)[0] === "ticket") {
      setMetadata(generateMetadataStructure(ticketTemp, []));
    }
    if (Array.from(type)[0] === "issue") {
      setMetadata(generateMetadataStructure(issueTemp, []));
    }
  }, [type, issueTemp, ticketTemp]);

  return (
    <div className=" w-96 p-4 shadow-lg overflow-y-auto absolute top-0 right-0 h-full bg-white dark:bg-[#1C1C21] z-50">
      <div className="flex items-center justify-between">
        <Dropdown variant="light" size="sm">
          <DropdownTrigger>
            <Button
              color={
                Array.from(type)[0] === "ticket"
                  ? "danger"
                  : Array.from(type)[0] === "issue"
                  ? "primary"
                  : "success"
              }
              size="sm"
              variant="flat"
              className="capitalize"
            >
              New {Array.from(type)[0]}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Priority selection"
            variant="flat"
            disallowEmptySelection
            selectionMode="single"
            selectedKeys={type}
            onSelectionChange={setType}
          >
            <DropdownItem color="danger" key="ticket">
              New Ticket
            </DropdownItem>
            <DropdownItem color="primary" key="issue">
              New Issue
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <div className="flex items-center">
          <Button variant="light" size="sm" onClick={handleClear}>
            Reset
          </Button>
          <Button isIconOnly variant="light" size="sm" onClick={toggleDrawer}>
            <IoIosClose style={{ width: "50px" }} />
          </Button>
        </div>
      </div>
      <div className="text-xs ">
        <Input
          className="mt-4 text-xs rounded-lg"
          style={{
            fontSize: "12px",
            backgroundColor: "",
          }}
          classNames={inputClassNames}
          type="text"
          placeholder="Add Title"
          size="sm"
          variant="bordered"
          name="title"
          value={title}
          onChange={(e) => {
            setShowSimilar(false);
            setSelectedSimilar(null);
            setToggleSimilar(false);
            setTitle(e.target.value);
          }}
          onBlur={() => handleShow()}
        />
      </div>
      {showSimilar && (
        <div className="max-h-[250px] flex justify-center p-2 mb-2 overflow-y-auto">
          {loader ? (
            <Spinner size="sm" className=" w-full" />
          ) : (
            <div className=" bg-transparent w-full flex flex-col gap-2">
              {similarTickets?.filter(
                (item) => item.payload.type === Array.from(type)[0]
              ).length === 0 ? (
                <p className=" text-xs font-light">
                  No similar {Array.from(type)[0]} found
                </p>
              ) : (
                <>
                  <div>
                    {" "}
                    <span
                      onClick={() => setToggleSimilar(!toggleSimilar)}
                      className=" cursor-pointer hover:font-semibold text-xs font-light"
                    >
                      {toggleSimilar
                        ? `Show Less`
                        : `Found ${
                            similarTickets?.filter(
                              (item) =>
                                item.payload.type === Array.from(type)[0]
                            ).length
                          } of similar ${Array.from(type)[0]}s`}
                    </span>
                  </div>
                  {toggleSimilar &&
                    similarTickets
                      ?.filter(
                        (item) => item.payload.type === Array.from(type)[0]
                      )

                      .map((item, index) => (
                        <Card
                          onClick={() => handleSetProps(item?.payload)}
                          isPressable
                          fullWidth
                          shadow="none"
                          className={`rounded-md overflow-visible border border-divider ${
                            item?.payload?.latest === selectedSimilar
                              ? " bg-gray-200"
                              : ""
                          }`}
                          key={index}
                        >
                          <CardBody className="p-2 flex flex-col gap-2">
                            <div className=" flex items-center justify-between ">
                              <div className=" flex gap-2 items-center">
                                <Chip
                                  size="sm"
                                  variant="flat"
                                  color={
                                    Array.from(type)[0] === "ticket"
                                      ? "danger"
                                      : "primary"
                                  }
                                >
                                  {Array.from(type)[0] === "ticket"
                                    ? "TKT"
                                    : "ISS"}
                                  -{item?.payload?.latest}
                                </Chip>
                                <p className=" font-light">
                                  {item?.payload?.title.length > 20
                                    ? `${item?.payload?.title.slice(0, 20)}...`
                                    : item?.payload?.title}
                                </p>
                              </div>
                              <Avatar
                                color={
                                  Colors[
                                    Number(item?.payload?.assigned) % numColors
                                  ]
                                }
                                style={{ width: "22px", height: "22px" }}
                                showFallback
                                radius="sm"
                                name={
                                  res[String(item?.payload?.assigned)]?.FirstName
                                    ? res[
                                      String(item?.payload?.assigned)
                                      ]?.FirstName?.charAt(0).toUpperCase()
                                    : res[
                                      String(item?.payload?.assigned)
                                      ]?.UserName?.charAt(0).toUpperCase()
                                }
                                size="sm"
                                src={`${
                                  process.env.REACT_APP_STAGE === "Dev"
                                    ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}`
                                    : ""
                                }${
                                  res[String(item?.payload?.assigned)]?.ProfilePicture
                                }`}
                              />
                            </div>
                            <p className=" text-xs font-light">
                              {item?.payload?.description
                                .replace(/<[^>]*>/g, "")
                                .slice(0, 200)}
                              {item?.payload?.description.replace(
                                /<[^>]*>/g,
                                ""
                              ).length > 200
                                ? "..."
                                : ""}
                            </p>
                          </CardBody>
                        </Card>
                      ))}
                </>
              )}
            </div>
          )}
        </div>
      )}
      <Divider className={showSimilar ? "" : "mt-4"} />
      <div className=" h-auto my-4 border border-divider rounded-lg">
        <EditorProvider
          placeholder="Enter your description"
          editorProps={props()}
          slotBefore={
            <MenuBar
              imageArray={imageArray}
              setImageArray={setImageArray}
              descriptionContent={descriptionContent}
              setDescriptionContent={setDescriptionContent}
              clear={clearEditor}
              initial={start}
              setInitial={setStart}
            />
          }
          extensions={extensions(userNames)}
          content={descriptionContent}
        ></EditorProvider>
      </div>
      <Divider />
      <div className="h-4"></div>
      <div style={{ fontSize: "12px" }}>
        <div className="flex gap-2 text-center items-center">
          <p className="flex w-32 gap-2 text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
              />
            </svg>
            Part<span className="text-red-500">*</span>
          </p>
          <div className=" w-full  flex ">
            {partData.length > 0 && (
              <PartPicker
                divRef={divRef}
                inputRef={inputRef}
                parsedParts={parsedParts}
                selected={selectedPart}
                setSelected={setSelectedPart}
                parts={partData}
              />
            )}
          </div>
        </div>
      </div>
      {!(selectedPart.size === 0) && (
        <div className=" mt-2 flex flex-col gap-4">
          <Divider className="mt-2" />
          <div className="flex gap-2 items-center">
            <p className="w-32 gap-2   text-xs text-center flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              Assigned to<span className=" text-red-600  "> *</span>
            </p>
            <div className="w-40">
              <Dropdown size="sm">
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className=" justify-start capitalize"
                  >
                    {Array.from(selectedReporter).length === 0 ? (
                      <p className=" text-xs ">Assign user</p>
                    ) : (
                      <div className="flex gap-2 items-center">
                        <Avatar
                          color={Colors[Number(reporterInfo.id) % numColors]}
                          style={{ width: "22px", height: "22px" }}
                          showFallback
                          radius="sm"
                          name={
                            reporterInfo.name
                              ? reporterInfo.name?.charAt(0).toUpperCase()
                              : reporterInfo.username?.charAt(0).toUpperCase()
                          }
                          className="flex-shrink-0"
                          size="sm"
                          src={reporterInfo.url}
                        />
                        <div className="flex flex-col">
                          <span className="text-xs">
                            {reporterInfo.name
                              ? reporterInfo.name.length > 25
                                ? `${reporterInfo.name.slice(0, 25)}...`
                                : reporterInfo.name
                              : reporterInfo.username.length > 25
                              ? `${reporterInfo.username.slice(0, 25)}...`
                              : reporterInfo.username}
                          </span>
                        </div>
                      </div>
                    )}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  ref={dropdownref}
                  aria-label="Select Tenant"
                  variant="flat"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={selectedReporter}
                  onSelectionChange={setSelectedReporter}
                >
                  {users.map((report) => {
                    const colorIndex = report && report.id % numColors;
                    return (
                      <DropdownItem
                        onClick={() => updateReporter(report)}
                        key={report.id}
                      >
                        <div className="flex gap-2 items-center">
                          <Avatar
                            style={{ width: "22px", height: "22px" }}
                            showFallback
                            name={
                              report.FirstName
                                ? report.FirstName?.charAt(0).toUpperCase()
                                : report.username?.charAt(0).toUpperCase()
                            }
                            className="flex-shrink-0"
                            radius="sm"
                            size="sm"
                            color={Colors[colorIndex]}
                            src={
                              report.ProfilePicture
                                ? process.env.REACT_APP_STAGE === "Dev"
                                  ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${report.ProfilePicture.formats.small.url}`
                                  : `${report.ProfilePicture.formats.small.url}`
                                : null
                            }
                          />
                          <div className="flex flex-col">
                            <span className="text-xs">
                              {report.FirstName
                                ? report.FirstName
                                : report.username}{" "}
                              {report?.LastName}
                            </span>
                          </div>
                        </div>
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <p className="w-32 gap-2 text-xs text-center flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
                />
              </svg>
              Priority<span className=" text-red-600"> *</span>
            </p>
            <div className="w-40">
              <Dropdown variant="light" size="sm">
                <DropdownTrigger>
                  <Button
                    size="sm"
                    variant="light"
                    className="capitalize justify-start"
                  >
                    {selected ? (
                      <div className="flex items-center gap-2">
                        {selectedData.icon}
                        <p className="text-xs">{selected}</p>
                      </div>
                    ) : (
                      <p className="text-xs text-start">Select Priority</p>
                    )}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  ref={dropdownref}
                  aria-label="Priority selection"
                  variant="flat"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={selectedPriority}
                  onSelectionChange={setSelectedPriority}
                >
                  {Object.keys(priorityData).map((priority) => (
                    <DropdownItem
                      className="flex items-center w-full gap-2"
                      key={priority}
                      onClick={() => setSelectedPriority(new Set([priority]))}
                    >
                      <div className="flex flex-row gap-2">
                        {priorityData[priority].icon}
                        <p className="w-14">{priority}</p>
                      </div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          {Array.from(type)[0] === "issue" && sprints.length > 0 && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2   text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  />
                </svg>
                Sprint
              </p>
              <div className="w-40">
                <Dropdown>
                  <DropdownTrigger>
                    <div className="w-full pl-4 text-xs cursor-pointer">
                      {Array.from(selectedSprint).length === 0
                        ? "Add sprint"
                        : `${
                            parsedSprints[Number(Array.from(selectedSprint)[0])]
                              ?.Name
                          }/ ${
                            sprintNumber === "Backlog"
                              ? "Backlog"
                              : `Sprint ${sprintNumber}`
                          }`}
                    </div>
                  </DropdownTrigger>
                  <DropdownMenu
                    ref={inputRef}
                    selectedKeys={selectedSprint}
                    onSelectionChange={setSelectedSprint}
                    selectionMode="single"
                  >
                    <DropdownSection isReadOnly>
                      <DropdownItem
                        classNames={{
                          base: "gap-0 data-[selectable=true]:focus:bg-white",
                          selectedIcon: "w-0",
                        }}
                        isReadOnly
                        key="new"
                      >
                        <Input
                          className="w-full"
                          size="sm"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search sprint.."
                          style={{ fontSize: "12px" }}
                        ></Input>
                      </DropdownItem>
                    </DropdownSection>
                    <DropdownSection className="overflow-y-auto max-h-[100px]">
                      {filteredSprints.map((item) => {
                        const iconDetail = basicIcons.filter(
                          (icon) =>
                            icon.name.toLowerCase() ===
                            item?.attributes?.Icon?.toLowerCase()
                        );
                        console.log(iconDetail);

                        return (
                          <DropdownItem
                            onMouseEnter={() => handleMouseEnter(item.id)}
                            onMouseLeave={() => handleMouseLeave()}
                            className="hover:bg-[#F4F4F5]"
                            isReadOnly
                            key={item.id}
                          >
                            <Popover
                              isOpen={onHover === item.id}
                              showArrow
                              placement="left-start"
                            >
                              <PopoverTrigger>
                                <div className="w-full text-xs flex gap-4 items-center">
                                  <svg
                                    xmlns={iconDetail[0]?.xmlns}
                                    fill={iconDetail[0]?.fill || "none"}
                                    viewBox={
                                      iconDetail[0]?.viewBox || "0 0 24 24"
                                    }
                                    strokeWidth={
                                      iconDetail[0]?.strokeWidth || 1.5
                                    }
                                    stroke={
                                      iconDetail[0]?.stroke || "currentColor"
                                    }
                                    className={
                                      iconDetail[0]?.className || "size-4"
                                    }
                                  >
                                    <path
                                      strokeLinecap={
                                        iconDetail[0]?.path?.d || "round"
                                      }
                                      strokeLinejoin={
                                        iconDetail[0]?.path?.d || "round"
                                      }
                                      d={
                                        iconDetail[0]?.path?.d ||
                                        "m4.5 12.75 6 6 9-13.5"
                                      }
                                    />
                                  </svg>
                                  <p>{item.attributes.Title}</p>
                                </div>
                              </PopoverTrigger>
                              <PopoverContent className="flex flex-col items-center gap-2 justify-center px-2 py-2">

                                <Button
                                  onClick={() =>
                                    handleSelectSprint(
                                      item.id,
                                      Number(item.attributes.Current)
                                    )
                                  }
                                  variant="light"
                                  size="sm"
                                  className="w-40 p-0"
                                >
                                  <div className="w-full text-xs flex p-1 gap-2 items-center">
                                    <ScredriverWrench size="size-4" />

                                    <p>Sprint {item.attributes.Current}</p>
                                  </div>
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleSelectSprint(
                                      item.id,
                                      Number(item.attributes.Current) + 1
                                    )
                                  }
                                  variant="light"
                                  size="sm"
                                  className="w-40 p-0"
                                >
                                  <div className="w-full text-xs flex p-1 gap-2 items-center">
                                    <ChevronArrow size="size-4" />

                                    <p>
                                      Sprint{" "}
                                      {Number(item.attributes.Current) + 1}
                                    </p>
                                  </div>
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleSelectSprint(item.id, "Backlog")
                                  }
                                  variant="light"
                                  size="sm"
                                  className="w-40 p-0"
                                >
                                  <div className="w-full text-xs flex gap-2 p-1 items-center">
                                    <WarningIcon size="size-4" />
                                    <p>Backlog</p>
                                  </div>
                                </Button>
                              </PopoverContent>
                            </Popover>
                          </DropdownItem>
                        );
                      })}
                    </DropdownSection>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          )}
          {Array.from(selectedKeys).includes("tags") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2   text-xs text-center flex items-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 6h.008v.008H6V6Z"
                  />
                </svg>
                Tags
              </p>
              <Tags
                param={tenant.name}
                customTags={customTags}
                org={tenant.id}
                dropdownref={dropdownref}
                tags={tags}
                className="text-xs text-start flex flex-row  w-10"
                setTags={setTags}
                tagsDetail={tagsDetail}
                setTagsDetail={setTagsDetail}
                modalref={modalref}
              />
            </div>
          )}
          {Array.from(selectedKeys).includes("date") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2  text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>
                Target Close Date
              </p>
              <div className="w-40">
                <DatePicker
                  variant="bordered"
                  minValue={today(getLocalTimeZone())}
                  placeholder="Add Target Cloase Date"
                  selectedDate={selectedDate}
                  onChange={handleDateChange}
                  size="sm"
                />
              </div>
            </div>
          )}
          {Array.from(selectedKeys).includes("owner") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2  text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                Owner
              </p>
              <div className="w-40">
                <Button
                  size="sm"
                  variant="light"
                  className="justify-start w-40"
                >
                  <div className="flex gap-2 items-center">
                    <Avatar
                      color={Colors[Number(ownerInfo?.id) % numColors]}
                      style={{ width: "22px", height: "22px" }}
                      showFallback
                      radius="sm"
                      name={
                        ownerInfo?.name
                          ? ownerInfo.name?.charAt(0)?.toUpperCase()
                          : ownerInfo.username?.charAt(0)?.toUpperCase()
                      }
                      className="flex-shrink-0"
                      size="sm"
                      src={ownerInfo?.url}
                    />
                    <div className="flex flex-col">
                      <span className="text-xs w-full">
                        {ownerInfo.name ? ownerInfo.name : ownerInfo.username}
                      </span>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          )}
          {Array.from(selectedKeys).includes("days") && (
            <div className="flex gap-2 items-center">
              <p className="w-32 gap-2   text-xs text-center flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                  />
                </svg>
                Estimated Days
              </p>
              <div className="w-40">
                <Input
                  value={selectedEstimatedDays}
                  variant="bordered"
                  placeholder="Add days"
                  classNames={{
                    input: ["bg-white", "hover:bg-white"],
                    innerWrapper: ["bg-white", "hover:bg-white"],
                    inputWrapper: [
                      "shadow-none",
                      "border",
                      "border-divider",

                      "bg-white",
                      "dark:bg-white",
                      "hover:bg-white",
                      "dark:hover:bg-white",
                      "group-data-[focus=true]:bg-white",
                      "dark:group-data-[focus=true]:bg-white",
                      "data-[hover=true]:bg-white",
                    ],
                  }}
                  onChange={(e) => setSelectedEstimatedDays(e.target.value)}
                  type="number"
                  size="sm"
                />
              </div>
            </div>
          )}
          <AttributesRenderer
            attributesRef={customAttributeRef}
            attributes={Array.from(selectedKeys)}
            metadata={metadata}
            setMetadata={setMetadata}
            template={Array.from(type)[0] === "ticket" ? ticketTemp : issueTemp}
          />
          <div className="w-24">
            <Dropdown>
              <DropdownTrigger>
                <Button variant="light" size="sm" className="capitalize">
                  + Add Attributes
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Multiple selection example"
                variant="flat"
                closeOnSelect={false}
                disallowEmptySelection
                selectionMode="multiple"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
              >
                <DropdownItem key="tags">Tags</DropdownItem>
                <DropdownItem key="owner">Owner</DropdownItem>
                <DropdownItem key="date">Target Closing Date </DropdownItem>
                <DropdownItem key="days">Estimated Efforts</DropdownItem>
                {Array.from(type)[0] === "ticket"
                  ? ticketTemp?.map((item) => (
                      <DropdownItem key={item.name}>{item.name}</DropdownItem>
                    ))
                  : issueTemp?.map((item) => (
                      <DropdownItem key={item.name}>{item.name}</DropdownItem>
                    ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      )}
      <Divider className="my-4" />
      <LinkIssues
        type={Array.from(type)[0] === "ticket" ? "issue" : "ticket"}
        selected={selectedIssueIds}
        setSelected={setSelectedIssueIds}
        popoverRef={modalref}
        inputRef={inputRef}
      />
      <div className="flex items-center mt-2 justify-end">
        <div className="flex items-center">
          <Button
            size="sm"
            className={`ml-2 text-white ${
              title === "" ||
              descriptionContent === "" ||
              Array.from(selectedReporter).length === 0 ||
              descriptionContent === "<p></p>" ||
              Array.from(selectedPart).length === 0 ||
              Array.from(selectedPriority).length === 0
                ? ``
                : `bg-[rgb(51,51,245)]`
            } `}
            disabled={
              title === "" ||
              descriptionContent === "" ||
              Array.from(selectedReporter).length === 0 ||
              descriptionContent === "<p></p>" ||
              Array.from(selectedPart).length === 0 ||
              Array.from(selectedPriority).length === 0
            }
            onClick={handleSubmit}
          >
            + Create
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
