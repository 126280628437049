import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  Input,
  ModalBody,
  ModalHeader,
  useDisclosure,
  NextUIProvider,
  Breadcrumbs,
  BreadcrumbItem,
} from "@nextui-org/react";
import {
  DeleteSingleAttribute,
  UpdateOneUserData,
  getOneData,
  uploadFile,
} from "../../controllers/strapiController";
import ModalPopup from "../../components/Modal/ModalPopup";
import DeleteModal from "../../components/Modal/DeleteModal";
import { useNavigate, useParams } from "react-router-dom";
import { Switch } from "@nextui-org/react";
import HelixLoader from "../../components/Loader/HelixLoader";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { useSelector } from "react-redux";
import { orgInfo, selectOrgData } from "../../redux/actions";

const initialState = {
  FirstName: "",
  LastName: "",
  ProfilePicture: "",
  Password: "",
}

const Account = () => {
  const [UserDetails, setUserDetails] = useState(null);
  const [Loader, setLoader] = useState(true);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const fileInputRef = useRef();
  const orgData = useSelector(selectOrgData);
  const navigate = useNavigate()
  const [showInput, setShowInput] = useState(false);
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onOpenChange: onModalOpenChange,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
  } = useDisclosure();
  const [FileUpload, setFileUpload] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [Response, setResponse] = useState("");
  const Navigate = useNavigate();

  const [formData, setFormData] = useState(initialState);

  const commonProps = {
    key: "outside",
    size: "sm",
    radius: "sm",
    labelPlacement: "outside"
  }

  useEffect(() => {
    setLoader(true);
    getOneData("users", UserId).then((data) => {
      setLoader(false);

      setFormData({
        FirstName: data.FirstName,
        LastName: data.LastName,
        Password: "",
        JobTitle:data?.JobTitle,
        ProfilePicture: data && data.ProfilePicture !== null
        ? process.env.REACT_APP_STAGE === "Dev"
          ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data.ProfilePicture.url}`
          : `${data.ProfilePicture.url}`
        : ""
      });
      setUserDetails(data);
    });
  }, [Response,UserId]);

  const { org } = useParams();
  const handleNavigate = () => {
    navigate(`/${org}/settings/account`);
  };

  const UploadFileHandler = async () => {
    setLoader(true);
    let formData = new FormData();
    formData.append("files", FileUpload);
    const response = await uploadFile(formData);
    if (response) {
      setLoader(false);
      const URLresponse = response && response[0];
      URLresponse && setFileUploaded(URLresponse);
    }
  };

  useEffect(() => {
    UploadFileHandler();
  }, [FileUpload]);

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);


  const UploadedUser =
    fileUploaded && fileUploaded !== null
      ? process.env.REACT_APP_STAGE === "Dev"
        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${fileUploaded.url}`
        : `${fileUploaded.url}`
      : "";

  const UpdateProfile = async (onClose) => {
    setLoader(true);
    const payload = {
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      ProfilePicture: fileUploaded,
      Password: formData.Password,
      JobTitle:formData?.JobTitle
    };

    const response = await UpdateOneUserData("users", UserId, payload);
    if (response) {
      onClose && onClose();
      setLoader(false);
      setResponse(response);
      setFormData(initialState);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];
      setFileUpload(file);
      setSelectedFile(file.name);
    } else {
      setFileUpload(null);
      setSelectedFile(null);
    }
  };

  const Handleremove = async () => {
    setFileUploaded(null);
    setFormData({ ...formData, ProfilePicture: "" }); 
  };



  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const DeleteHandler = async () => {
    setLoader(true);
    try {
      const formData = {
        organizations : {
          disconnect : [orgData.id],
        },
        OrgRoute : ""
      }
      const response = await UpdateOneUserData("users",UserId,formData);
      if (response) {
        setLoader(false);
        Navigate("/sign-in");
        sessionStorage.clear();
      }
    }
    catch(e){
      console.log(e)
    }
    // const response = await DeleteSingleAttribute("users", UserId);
    
  };

  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const colorIndex = UserDetails && UserDetails.id % numColors;
  const ProfileURL =
    UserDetails && UserDetails.ProfilePicture !== null
      ? process.env.REACT_APP_STAGE === "Dev"
        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${UserDetails.ProfilePicture.url}`
        : `${UserDetails.ProfilePicture.url}`
      : "";


  const ModalBodyData = () => {
    return (
      <>
        <ModalHeader>Edit profile</ModalHeader>
        <ModalBody>
          <div className="flex flex-row items-center gap-6">
            <Avatar
              className="rounded-md"
              radius="sm"
              size="md"
              src={UploadedUser ? UploadedUser : formData?.ProfilePicture}
              color={Colors[colorIndex]}
              name={
                UserDetails && UserDetails.FirstName
                  ? UserDetails.FirstName.charAt(0).toUpperCase()
                  : UserDetails && UserDetails.email
                    ? UserDetails.email.charAt(0).toUpperCase()
                    : ""
              }
            />
            <input
              ref={fileInputRef}
              type="file"
              onChange={(event) => handleFileChange(event)}
              className="hidden"
            />
            <Button onClick={handleButtonClick} variant='light'
          className="text-xs border border-divider"
          size="sm" >
              Select profile image
            </Button>
            {formData?.ProfilePicture!==''||UploadedUser ? (
              <p className="text-xs cursor-pointer" onClick={Handleremove}>
                Remove
              </p>
            ) : (
              ""
            )}
          </div>

          <div className="flex flex-col gap-4 pt-4">
            <div>
              <p className="text-xs font-normal text-slate-500 pb-1">
                First name
              </p>
              <Input
                {...commonProps}
                type="text"
                defaultValue={formData.FirstName}
                onChange={(e) =>
                  setFormData({ ...formData, FirstName: e.target.value })
                }
                placeholder="Enter your full name"
              />
            </div>
            <div>
              <p className="text-xs font-normal text-slate-500 pb-1">
                Last name
              </p>
              <Input
                {...commonProps}
                type="text"
                label=""
                defaultValue={formData.LastName}
                onChange={(e) =>
                  setFormData({ ...formData, LastName: e.target.value })
                }
                placeholder="Enter your last name"
              />
            </div>
            <div>
              <p className="text-xs font-normal text-slate-500 pb-1">
                Designation
              </p>
              <Input
                {...commonProps}
                type="text"
                label=""
                defaultValue={formData.JobTitle}
                onChange={(e) =>
                  setFormData({ ...formData, JobTitle: e.target.value })
                }
                placeholder="Enter your Job title"
              />
            </div>
            <div className="cursor-not-allowed">
              <p className="text-xs font-normal text-slate-500 pb-1">Email</p>
              <Input
                isDisabled
                {...commonProps}
                type="text"
                label=""
                value={UserDetails && UserDetails.email}
                placeholder="Enter your email"
              />
            </div>
          </div>
        </ModalBody>
      </>
    );
  };

  const footerCreate = (onClose) => {
    return (
      <>
        <Button
          variant='light'
          className="text-xs border border-divider"
          size="sm" 
          onClick={() => UpdateProfile(onClose)}>
          Update
        </Button>
      </>
    );
  };

  return (
    <div className="p-8 h-32">
      {Loader && (
        <div
          className={`absolute z-[100] flex items-center justify-center inset-0 h-screen w-screen bg-white'`}
        >
          <HelixLoader />
        </div>
      )}
      <NextUIProvider className="flex justify-start items-start">
        <div className=" flex cursor-pointer text-center justify-center items-center">
          <Breadcrumb />
          <Breadcrumbs size="sm" isDisabled>
            <BreadcrumbItem onClick={handleNavigate}>Settings</BreadcrumbItem>
            <BreadcrumbItem>Account</BreadcrumbItem>
          </Breadcrumbs>
        </div>
      </NextUIProvider>
      <div className="max-w-2xl m-auto h-fit flex flex-col gap-6 pt-8">
        <NextUIProvider>
          <Card className="shadow-none border border-divider rounded-2xl p-4">
            <CardBody>
              <div className="flex flex-row items-center gap-8">
                <Avatar
                  radius="md"
                  className="rounded-md"
                  size="md"
                  color={Colors[colorIndex]}
                  src={ProfileURL}
                  name={
                    UserDetails && UserDetails.FirstName
                      ? UserDetails.FirstName.charAt(0).toUpperCase()
                      : UserDetails && UserDetails.email
                        ? UserDetails.email.charAt(0).toUpperCase()
                        : ""
                  }
                />
                <p className="text-lg font-medium">
                  {UserDetails && UserDetails.FirstName !== null
                    ? `${UserDetails.FirstName} ${UserDetails.LastName}`
                    : UserDetails && UserDetails.email}
                </p>
              </div>
              <div className="flex flex-row gap-6 w-full text-base pt-7 font-normal">
                <p className=" w-28  text-slate-400 text-xs">Display Name</p>
                <p className=" text-xs ">
                  {UserDetails &&
                    (UserDetails.FirstName !== null ||
                      UserDetails.LastName !== null)
                    ? UserDetails &&
                    `${UserDetails.FirstName !== null
                      ? UserDetails.FirstName
                      : ""
                    } ${UserDetails.LastName !== null
                      ? UserDetails.LastName
                      : ""
                    }`
                    : ""}
                </p>
              </div>
              <div className="flex  gap-6 flex-row w-full text-base pt-3 font-normal">
                <p className=" w-28 text-xs text-slate-400">Email</p>
                <p className=" text-xs">
                  {UserDetails && UserDetails.email !== null
                    ? UserDetails.email
                    : ""}
                </p>
              </div>
              <div className="flex  gap-6 flex-row w-full text-base pt-3 font-normal">
                <p className=" w-28 text-xs text-slate-400">Designation </p>
                <p className=" text-xs">
                  {UserDetails?.JobTitle !== null
                    ? UserDetails?.JobTitle
                    : "--"}
                </p>
              </div>
              <div className="pt-6">
                <Button
                  variant="light"
                  className="text-xs border border-divider"
                  size="sm"
                  onPress={onModalOpen}
                >
                  Edit profile
                </Button>
              </div>
            </CardBody>
          </Card>
        </NextUIProvider>
        <NextUIProvider>
          <Card className="shadow-none border border-divider rounded-2xl p-4">
            <CardBody>
              <div>
                <p className="mb-2">External Identities</p>
                <p className="mb-4 text-xs">
                  Link your external identies to help keep your work across
                  services in sync without manual updates. This contains both
                  manually added identities and those we discovered for you.
                </p>
                <div className="flex gap-4">
                  <Button variant="light"
                  className="text-xs border border-divider"
                  size="sm">
                    Link BitBucket account
                  </Button>
                  <Button variant="light"
                  className="text-xs border border-divider"
                  size="sm">
                    Link Github account
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </NextUIProvider>
        <NextUIProvider>
          <Card className="shadow-none border border-divider min-h-28 rounded-2xl p-4">
            <CardBody>
              <p className="text-md font-normal">Enable Notifications</p>
              <p className="text-xs pt-4">
                Enable Notifications to get notified about your knowledge base
                activities to Slack, Teams, Discord and email.
              </p>
              <div className="mt-8">
                <div className="flex justify-between">
                  <div className="flex flex-col gap-2">
                    <p className="text-sm">Slack</p>
                    <p className="text-tiny text-default-400">
                      Get notified through Slack
                    </p>
                  </div>
                  <Switch
                    color="danger"
                    size="sm"
                    checked={showInput}
                    onChange={(e) => setShowInput(e.target.checked)}
                  />
                </div>
                <div className="mt-4">
                  {showInput && (
                    <div className="mt-12">
                      <Input
                        {...commonProps}
                        type="text"
                        size='sm'
                        className=" w-1/2"
                        labelPlacement="outside"
                        placeholder="Add your slack channel URL"
                        label="Slack channel"
                        color=""
                      />
                    </div>
                  )}
                </div>

                <div className="flex justify-between mt-4">
                  <div className="flex flex-col gap-2">
                    <p className="text-sm">Teams</p>
                    <p className="text-tiny text-default-400">
                      Get notified through Teams
                    </p>
                  </div>
                  <Switch color="secondary" size="sm" />
                </div>
              </div>
            </CardBody>
          </Card>
        </NextUIProvider>
        <NextUIProvider>
          <Card className="shadow-none border border-danger rounded-2xl p-4">
            <CardBody>
              <p className="text-md font-normal">Danger Zone</p>
              <p className="text-md font-normal pt-4">Change Password</p>
              <p className="text-xs font-thin pt-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe,
                eaque.
              </p>
              <div className="flex gap-4 pt-4">
                <Input
                  radius="sm"
                  size='sm'
                  variant={""}
                  label=""
                  labelPlacement={"outside"}
                  value={formData?.Password}
                  onChange={(e) =>
                    setFormData({ ...formData, Password: e.target.value })
                  }
                  placeholder="Enter your new password"
                  type="password"
                />
                <div>
                <Button onClick={() => UpdateProfile()} color="secondary" className="text-xs" size='sm'>
                  Change Password
                </Button>
                </div>
              </div>
              <p className="text-md font-normal mt-4">Delete your account</p>
              <p className="text-xs pt-4">
                This will permanently remove your account and you will lose
                access to all your data inside your space.
              </p>
              <div className="pt-8">
                <Button
                  size="sm"
                  onPress={onDeleteOpen}
                  className="text-xs"
                  color="danger"
                >
                  Delete account
                </Button>
              </div>
            </CardBody>
          </Card>
        </NextUIProvider>

      </div>
      <ModalPopup
        isOpen={isModalOpen}
        onOpenChange={onModalOpenChange}
        ModalBodyData={ModalBodyData}
        footer={footerCreate}
      />
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteData={""}
        setDeleteData={""}
        Collectionname={"user"}
        setLoader={setLoader}
        DeleteHandler={DeleteHandler}
      />
    </div>
  );
};

export default Account;
