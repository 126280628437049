import React from "react";
import Breadcrumb from "../../components/BreadCrumb/Breadcrumb";
import { BreadcrumbItem, Breadcrumbs} from "@nextui-org/react";

export const pages = ["tickets","issues","components","accounts","customers","inbox"];
function GeneralHeader() {
    
    
  return (
    <div className="flex mx-8 justify-between pt-8">
        <div className="w-full flex items-center justify-between">
          <div className="flex text-center items-center">
            <Breadcrumb />
            <Breadcrumbs size="sm">
              <BreadcrumbItem >
                General
              </BreadcrumbItem>
            </Breadcrumbs>
          </div>
        </div>
      </div>
  );
}

export default GeneralHeader;
