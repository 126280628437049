export const postData = async (id, formData, tenant) => {
  try {
    // Generate timestamp as ID
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/postData`;
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        formData,
        id,
        tenant,
        collection: process.env.REACT_APP_COLLECTION_NAME,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllInbox = async (tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/getAll/${tenant}/${process.env.REACT_APP_COLLECTION_INBOX}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data[0];
  } catch (err) {
    throw err;
  }
};

export const postDataInbox = async (id, formData, tenant) => {
  try {
    // Generate timestamp as ID
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/postData`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        formData,
        id,
        tenant,
        collection: process.env.REACT_APP_COLLECTION_INBOX,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSingleDocument = async (id, tenant) => {
  let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
  try {
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/getOne?collection=${process.env.REACT_APP_COLLECTION_INBOX}&tenant=${tenant}&id=${id}`;

    const response = await fetch(url, {
      // Use the constructed URL
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSingleDoc = async (id, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/getOne/?collection=${process.env.REACT_APP_COLLECTION_NAME}&tenant=${tenant}&id=${id}`;

    const response = await fetch(url, {
      // Use the constructed URL
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSingleTicket = async (id, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/getOne?collection=${process.env.REACT_APP_COLLECTION_NAME}&tenant=${tenant}&id=${id}`;

    const response = await fetch(url, {
      // Use the constructed URL
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};


export const getDocument = async (key, value, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/filter-doc?collection=${process.env.REACT_APP_COLLECTION_NAME}&tenant=${tenant}`;

    const body = {
      filters: [
        {
          key: key,
          value: value,
        },
      ],
    };
    const response = await fetch(url, {
      // Use the constructed URL
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMultipleFilters = async (value, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/filter-doc?collection=${process.env.REACT_APP_COLLECTION_NAME}&tenant=${tenant}`;
    const body = {
      filters: value,
    };
    const response = await fetch(url, {
      // Use the constructed URL
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMultipleFiltersInbox = async (value, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/filter-doc?collection=${process.env.REACT_APP_COLLECTION_INBOX}&tenant=${tenant}`;
    const body = {
      filters: value,
    };
    const response = await fetch(url, {
      // Use the constructed URL
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMultipleFiltersSimilar = async (query, tenant) => {
  let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
  try {
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/get-similar?collection=${process.env.REACT_APP_COLLECTION_NAME}&tenant=${tenant}&query=${query}`;

    const response = await fetch(url, {
      // Use the constructed URL
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};


export const patchData = async (formData, id, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/patch-data?id=${id}&tenant=${tenant}&collection=${process.env.REACT_APP_COLLECTION_NAME}`;

    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const patchDataInbox = async (formData, id, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/patch-data?id=${id}&tenant=${tenant}&collection=${process.env.REACT_APP_COLLECTION_INBOX}`;

    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// TODO
export const deleteData = async (id, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/delete-data/?id=${id}&tenant=${tenant}&collection=${process.env.REACT_APP_COLLECTION_NAME}`;

    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteDataInbox = async (id, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/delete-data/?id=${id}&tenant=${tenant}&collection=${process.env.REACT_APP_COLLECTION_NAME}`;

    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const postIngest = async (id, formData, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    // Generate timestamp as ID
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/ingest-doc?id=${id}&tenant=${tenant}&collection=${process.env.REACT_APP_UPLOAD_COLLECTION_NAME}`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllDocs = async (tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/getAll-doc?tenant=${tenant}&collection=${process.env.REACT_APP_UPLOAD_COLLECTION_NAME}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    throw err;
  }
};

export const deleteDoc = async (id, tenant) => {
  try {
    let jwt;

    if (JSON.parse(sessionStorage.getItem("userData"))) {
      jwt = JSON.parse(sessionStorage.getItem("userData")).jwt;
    } else {
      jwt = "";
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/haya/delete-doc?id=${id}&tenant=${tenant}&collection=${process.env.REACT_APP_UPLOAD_COLLECTION_NAME}`;

    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${jwt}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
