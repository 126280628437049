import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import {
  MenuBar,
  props,
  extensions,
} from "../../components/markdown/componenteditor";
import { EditorProvider } from "@tiptap/react";
import { IoIosClose } from "react-icons/io";
import Tags from "../../components/Tag/tags";
import {
  CreateData,
  getOneData,
  UpdateData,
} from "../../controllers/strapiController";
import { RiVipDiamondLine } from "react-icons/ri";
import toast, { Toaster } from "react-hot-toast";
import { sendUpdateTickets } from "../../utils/mentions";
import { useSelector } from "react-redux";
import { selectOrgData, selectResData } from "../../redux/actions";
import PartPicker from "../../components/Drawer/PartPicker";
import { generateMetadataStructure } from "../../utils/customAttributes";
import AttributesRenderer from "../../components/AttributesRenderer/AttributesRenderer";
import { inputClassNames } from "../../utils/default";

const CreateDrawer = ({
  type,
  parent,
  dropdownref,
  handleclose,
  modalref,
  org,
  parts,
  divRef,
  parsedParts,
  users,
  orgid,
  customTags,
  parentPath,
  inputRef,
  userNames,
  customAttributeRef,
}) => {
  const [selectedKeyss, setSelectedKeyss] = React.useState(
    new Set([type === "" ? "Product" : type])
  );
  const [template, setTemplate] = useState([]);
  const [name, setName] = useState("");
  const [descriptionContent, setDescriptionContent] = useState("");
  const [ownerInfo, setOwnerInfo] = useState({
    name: "",
    url: "",
    id: "",
    username: "",
  });
  const [owner, setOwner] = useState("");
  const [selectedPart, setSelectedPart] = useState(
    parent === "" ? new Set([]) : new Set([parent])
  );
  const orgData = useSelector(selectOrgData);
  const res = useSelector(selectResData);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [parentId, setParentId] = useState(parentPath);
  const userDataId = JSON.parse(sessionStorage.getItem("userData")).user.id;
  const [tags, setTags] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState(new Set([]));
  const [tagsDetail, setTagsDetail] = useState([]);
  const [isOpenPRD, setIsOpenPRD] = useState(false);
  const [isOpenDesign, setIsOpenDesign] = useState(false);
  const [isOpenOther, setIsOpenOther] = useState(false);
  const [titlePRD, setTitlePRD] = useState("");
  const [titleOther, setTitleOther] = useState("");
  const [titleDesign, setTitleDesign] = useState("");
  const [urlPRD, setUrlPRD] = useState("");
  const [urlOther, setUrlOther] = useState("");
  const [urlDesign, setUrlDesign] = useState("");
  const [prd, setPrd] = useState([]);
  const [start, setStart] = useState(false);
  const [design, setDesign] = useState([]);
  const [other, setOther] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const Colors = ["secondary", "success", "warning", "danger", "primary"];
  const numColors = Colors.length;
  const open = ["Ideation", "Prioritised", "Deprioritised"];
  const progress = ["UX Design Phase", "In development", "Testing"];
  const deployed = ["Staging", "Production"];
  const Inactive = ["Deprecated", "Won't do"];
  const [selectedStatus, setSelectedStatus] = useState(new Set(["Ideation"]));
  const [errors, setErrors] = useState({
    titlePRD: "",
    urlPRD: "",
    titleDesign: "",
    urlDesign: "",
    titleOther: "",
    urlOther: "",
  });
  const urlRegex =
    /^(https?:\/\/)?([\w\d-]+\.)+[a-z]{2,6}(:\d{1,5})?(\/[\w\d-]*)*(\?[^\s]*)?$/;

  const handleRemovePRD = (title) => {
    setPrd(prd.filter((item) => item.Title !== title));
  };

  const handleRemoveDesign = (title) => {
    setDesign(design.filter((item) => item.Title !== title));
  };

  const handleRemoveOther = (title) => {
    setOther(other.filter((item) => item.Title !== title));
  };

  const handleSubmitt = () => {
    const newErrors = {
      titlePRD: "",
      urlPRD: "",
      titleDesign: "",
      urlDesign: "",
      titleOther: "",
      urlOther: "",
    };

    if (!titlePRD) {
      newErrors.titlePRD = "Title PRD cannot be empty";
    }
    if (!titleDesign) {
      newErrors.titleDesign = "Title Design cannot be empty";
    }
    if (!titleOther) {
      newErrors.titleOther = "Title Other cannot be empty";
    }

    if (!urlRegex.test(urlPRD)) {
      newErrors.urlPRD = "Invalid URL for PRD";
    } else if (titlePRD === urlPRD) {
      newErrors.urlPRD = "URL for PRD cannot be the same as the title";
    }

    if (!urlRegex.test(urlDesign)) {
      newErrors.urlDesign = "Invalid URL for Design";
    } else if (titleDesign === urlDesign) {
      newErrors.urlDesign = "URL for Design cannot be the same as the title";
    }

    if (!urlRegex.test(urlOther)) {
      newErrors.urlOther = "Invalid URL for Other";
    } else if (titleOther === urlOther) {
      newErrors.urlOther = "URL for Other cannot be the same as the title";
    }
    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
    } else {
    }
  };

  const handleAddItem = (prop) => {
    if (prop === "PRD") {
      if (titlePRD === "" || urlPRD === "") {
        return;
      }
      setPrd((prev) => [{ Title: titlePRD, URL: urlPRD }, ...prev]);
      setTitlePRD("");
      setUrlPRD("");
      setIsOpenPRD(false);
    } else if (prop === "Design") {
      if (titleDesign === "" || urlDesign === "") {
        return;
      }
      setDesign((prev) => [{ Title: titleDesign, URL: urlDesign }, ...prev]);
      setTitleDesign("");
      setUrlDesign("");
      setIsOpenDesign(false);
    } else if (prop === "Other") {
      if (titleOther === "" || urlOther === "") {
        return;
      }
      setOther((prev) => [{ Title: titleOther, URL: urlOther }, ...prev]);
      setTitleOther("");
      setUrlOther("");
      setIsOpenOther(false);
    }
  };

  const toggleDrawer = () => {
    handleclose();
    setIsOpenDesign(false);
    setIsOpenPRD(false);
    setIsOpenOther(false);
  };

  const resetModalErrors = (modalType) => {
    if (modalType === "PRD") {
      setErrors((prevErrors) => ({ ...prevErrors, titlePRD: "", urlPRD: "" }));
    } else if (modalType === "Design") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        titleDesign: "",
        urlDesign: "",
      }));
    } else if (modalType === "Other") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        titleOther: "",
        urlOther: "",
      }));
    }
  };

  const handleSubmit = async () => {
    const arr = parts.filter(
      (item) =>
        item.attributes.Name === name && item.attributes.ParentPath === parentId
    );
    if (arr.length > 0) {
      toast("Component already exists", {
        duration: 3000,
        position: "bottom-right",
      });
      return;
    }
    const formData = {
      latest_part:
        Array.from(selectedKeyss)[0] === "Product"
          ? orgid.attributes.Product_latest === null
            ? 1
            : Number(orgid.attributes.Product_latest) + 1
          : Array.from(selectedKeyss)[0] === "Capability"
          ? orgid.attributes.Capability_latest === null
            ? 1
            : Number(orgid.attributes.Capability_latest) + 1
          : Array.from(selectedKeyss)[0] === "Feature"
          ? orgid.attributes.Feature_latest === null
            ? 1
            : Number(orgid.attributes.Feature_latest) + 1
          : Array.from(selectedKeyss)[0] === "Enhancement"
          ? orgid.attributes.Enhancement_latest === null
            ? 1
            : Number(orgid.attributes.Enhancement_latest) + 1
          : orgid.attributes.Runnable_latest === null
          ? 1
          : Number(orgid.attributes.Runnable_latest) + 1,
      Type: Array.from(selectedKeyss)[0],
      Name: name,
      Description: descriptionContent,
      Stage:
        Array.from(selectedKeyss)[0] === "Enhancement"
          ? Array.from(selectedStatus)[0]
          : "",
      Tags: tags,
      metdata: metadata,
      TotalTickets: "",
      TotalIssues: "",
      ParentPath: parentId,
      users_permissions_user: {
        disconnect: [],
        connect: [
          {
            id: Number(ownerInfo.id),
            position: {
              end: true,
            },
          },
        ],
      },
      organization: {
        disconnect: [],
        connect: [
          {
            id: Number(orgid.id),
            position: {
              end: true,
            },
          },
        ],
      },
      Documentation: {
        PRD: prd,
        Design: design,
        Other: other,
      },
      Tags: tags,
    };
    const orgData = {
      Product_latest:
        Array.from(selectedKeyss)[0] === "Product"
          ? Number(orgid.attributes.Product_latest) + 1
          : orgid.attributes.Product_latest,
      Capability_latest:
        Array.from(selectedKeyss)[0] === "Capability"
          ? Number(orgid.attributes.Capability_latest) + 1
          : orgid.attributes.Capability_latest,
      Feature_latest:
        Array.from(selectedKeyss)[0] === "Feature"
          ? Number(orgid.attributes.Feature_latest) + 1
          : orgid.attributes.Feature_latest,
      Enhancement_latest:
        Array.from(selectedKeyss)[0] === "Enhancement"
          ? Number(orgid.attributes.Enhancement_latest) + 1
          : orgid.attributes.Enhancement_latest,
      Runnable_latest:
        Array.from(selectedKeyss)[0] === "Runnable"
          ? Number(orgid.attributes.Runnable_latest) + 1
          : orgid.attributes.Runnable_latest,
    };
    try {
      await UpdateData("organizations", orgid.id, orgData);
      const data = await CreateData("parts", formData);
      console.log("data",data);
      await sendUpdateTickets(
        ownerInfo.id,
        null,
        descriptionContent,
        userDataId,
        "part",
        "create",
        users,
        res,
        "components",
        data.data.id,
        null,
        orgid.id
      );
      window.location.reload();
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  const updateOwner = (user) => {
    setOwnerInfo({
      username: user.username,
      id: user.id,
      name: user.FirstName,
      url: user.ProfilePicture
        ? process.env.REACT_APP_STAGE === "Dev"
          ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.ProfilePicture.formats.small.url}`
          : `${user.ProfilePicture.formats.small.url}`
        : null,
    });
  };
  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const template = await getOneData("organizations", orgData.id, [
          "component_metadata",
        ]);
        const temp = template.data.attributes.component_metadata ?? [];
        setMetadata(generateMetadataStructure(temp, []));
        setTemplate(template.data.attributes.component_metadata);
      } catch (error) {}
    };
    if (orgData.id) {
      fetchTemplate();
    }
  }, [orgData]);
  useEffect(() => {
    const filteredParts = parts.filter((item) =>
      (item?.attributes?.Type !== "Product"
        ? `${item?.attributes?.ParentPath?.split("/")
            ?.map((item) => parsedParts[Number(item)]?.Name)
            .join("/ ")}/ ${item?.attributes?.Name}`
        : `${item?.attributes?.Name}`
      )
        .toLowerCase()
        .includes(search.toLowerCase())
    );
    setFilteredOptions(filteredParts);
  }, [search, parts]);
  useEffect(() => {
    if (Array.from(selectedPart).length > 0) {
      const id = Array.from(selectedPart)[0];
      setParentId(
        parsedParts[id].ParentPath === ""
          ? `${id}`
          : `${parsedParts[id].ParentPath}/${id}`
      );
    }
  }, [selectedPart]);
  return (
    <div>
      <Toaster />
      <div className="w-96 p-4 shadow-lg overflow-y-auto absolute top-0 right-0 h-full bg-white dark:bg-[#1C1C21] z-50">
        <div className="flex items-center justify-between">
          {type !== "" ? (
            <Button
              size="sm"
              color={
                Array.from(selectedKeyss)[0] === "Product"
                  ? "primary"
                  : Array.from(selectedKeyss)[0] === "Capability"
                  ? "secondary"
                  : Array.from(selectedKeyss)[0] === "Feature"
                  ? "success"
                  : Array.from(selectedKeyss)[0] === "Enhancement"
                  ? "warning"
                  : "danger"
              }
              variant="flat"
              className="capitalize"
            >
              {Array.from(selectedKeyss)[0]}
            </Button>
          ) : (
            <Dropdown>
              <DropdownTrigger>
                <Button
                  size="sm"
                  color={
                    Array.from(selectedKeyss)[0] === "Product"
                      ? "primary"
                      : Array.from(selectedKeyss)[0] === "Capability"
                      ? "secondary"
                      : Array.from(selectedKeyss)[0] === "Feature"
                      ? "success"
                      : Array.from(selectedKeyss)[0] === "Enhancement"
                      ? "warning"
                      : "danger"
                  }
                  variant="flat"
                  className="capitalize"
                >
                  {Array.from(selectedKeyss)[0]}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Single selection example"
                variant="flat"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeyss}
                onSelectionChange={setSelectedKeyss}
                disabledKeys={
                  parts.length === 0
                    ? ["Capability", "Feature", "Enhancement", "Runnable"]
                    : []
                }
              >
                <DropdownItem key="Product" color="primary">
                  Product
                </DropdownItem>
                <DropdownItem key="Capability" color="secondary">
                  Capability
                </DropdownItem>
                <DropdownItem key="Feature" color="success">
                  Feature
                </DropdownItem>
                <DropdownItem key="Enhancement" color="warning">
                  Enhancement
                </DropdownItem>
                <DropdownItem key="Runnable" color="danger">
                  Runnable
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          <div className="flex items-center">
            <Button
              isIconOnly
              variant="light"
              size="sm"
              onClick={(e) => toggleDrawer()}
            >
              <IoIosClose style={{ width: "50px" }} />
            </Button>
          </div>
        </div>
        <div className="mt-4 mb-4 text-xs">
          <Input
            classNames={inputClassNames}
            size="sm"
            variant="bordered"
            className=" text-xs"
            style={{ fontSize: "12px" }}
            type="text"
            placeholder="Add Name"
            name="title"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <Divider />
        <div className="h-auto my-4 border border-divider rounded-lg">
          <EditorProvider
            placeholder="Enter your description"
            editorProps={props}
            extensions={extensions(userNames)}
            slotBefore={
              <MenuBar
                descriptionContent={descriptionContent}
                setDescriptionContent={setDescriptionContent}
                initial={start}
                setInitial={setStart}
              />
            }
            content=""
          />
        </div>
        <Divider />
        <div className="mt-4 flex flex-col gap-2">
          <div className=" flex gap-2">
            <p className=" w-32 gap-2 text-xs  flex  items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              Owner
              <span className=" text-red-600">*</span>
            </p>

            <Dropdown variant="light" size="sm">
              <DropdownTrigger>
                <Button
                  className="text-start justify-start w-40"
                  variant="light"
                  size="sm"
                >
                  {owner === "" ? (
                    <p className=" text-xs text-start ">Select Owner</p>
                  ) : (
                    <div className="flex gap-2 items-center">
                      <Avatar
                        style={{ width: "22px", height: "22px" }}
                        showFallback
                        radius="sm"
                        name={
                          ownerInfo.name
                            ? ownerInfo.name?.charAt(0).toUpperCase()
                            : ownerInfo.username?.charAt(0).toUpperCase()
                        }
                        color={Colors[Number(ownerInfo.id) % numColors]}
                        className="flex-shrink-0"
                        size="sm"
                        src={ownerInfo.url}
                      />
                      <div className="flex flex-col">
                        <span className="text-xs">
                          {ownerInfo.name ? ownerInfo.name : ownerInfo.username}
                        </span>
                      </div>
                    </div>
                  )}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Select Tenant"
                variant="flat"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={owner}
                onSelectionChange={setOwner}
              >
                {users.map((user) => (
                  <DropdownItem
                    onClick={(e) => updateOwner(user)}
                    key={user.id}
                  >
                    <div className="flex gap-2 items-center">
                      <Avatar
                        style={{ width: "22px", height: "22px" }}
                        showFallback
                        radius="sm"
                        name={
                          user.FirstName
                            ? user.FirstName?.charAt(0).toUpperCase()
                            : user.username?.charAt(0).toUpperCase()
                        }
                        color={Colors[Number(user.id) % numColors]}
                        className="flex-shrink-0"
                        size="sm"
                        src={
                          user.ProfilePicture
                            ? process.env.REACT_APP_STAGE === "Dev"
                              ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.ProfilePicture.formats.small.url}`
                              : `${user.ProfilePicture.formats.small.url}`
                            : null
                        }
                      />
                      <div className="flex flex-col">
                        <span className="text-xs">
                          {user.FirstName ? user.FirstName : user.username}{" "}
                          {user?.LastName}
                        </span>
                      </div>
                    </div>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          {Array.from(selectedKeyss)[0] === "Product" ? (
            <></>
          ) : (
            <div className="flex  gap-2 items-center">
              <p className="w-32 gap-2 flex text-xs">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                  />
                </svg>
                Select Part<span className=" text-red-600">*</span>
              </p>
              <div className="w-fit">
                <PartPicker
                  divRef={divRef}
                  inputRef={inputRef}
                  parsedParts={parsedParts}
                  selected={selectedPart}
                  setSelected={setSelectedPart}
                  parts={parts?.filter(
                    (item) =>
                      item.attributes.Type === "Product" ||
                      (Array.from(selectedKeyss)[0] === "Feature" &&
                        item.attributes.Type === "Capability") ||
                      (Array.from(selectedKeyss)[0] === "Enhancement" &&
                        (item.attributes.Type === "Feature" ||
                          item.attributes.Type === "Capability")) ||
                      (Array.from(selectedKeyss)[0] === "Runnable" &&
                        item.attributes.Type === "Enhancement")
                  )}
                />
              </div>
            </div>
          )}
          {Array.from(selectedKeyss)[0] === "Enhancement" ? (
            <div>
              <div className="flex  gap-2 items-center">
                <div className="flex w-32 gap-2 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
                    />
                  </svg>
                  <p className="w-24 text-xs">Status</p>
                </div>
                <div className="w-40">
                  <Dropdown>
                    <DropdownTrigger>
                      <Button
                        variant="light"
                        size="sm"
                        className="w-full flex justify-start"
                      >
                        {Array.from(selectedStatus).length === 0
                          ? "Select status"
                          : Array.from(selectedStatus)[0]}
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      className="h-[200px] overflow-y-auto"
                      disallowEmptySelection
                      selectionMode="single"
                      selectedKeys={selectedStatus}
                      onSelectionChange={setSelectedStatus}
                    >
                      <DropdownSection title={"Open"}>
                        {open.map((item) => (
                          <DropdownItem key={item}>{item}</DropdownItem>
                        ))}
                      </DropdownSection>
                      <DropdownSection title={"In progress"}>
                        {progress.map((item) => (
                          <DropdownItem key={item}>{item}</DropdownItem>
                        ))}
                      </DropdownSection>
                      <DropdownSection title={"Deployed"}>
                        {deployed.map((item) => (
                          <DropdownItem key={item}>{item}</DropdownItem>
                        ))}
                      </DropdownSection>
                      <DropdownSection title={"In active"}>
                        {Inactive.map((item) => (
                          <DropdownItem key={item}>{item}</DropdownItem>
                        ))}
                      </DropdownSection>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="flex gap-2 mb-4 items-center">
            <p className="flex w-32 gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 6h.008v.008H6V6Z"
                />
              </svg>

              <p className="w-24 text-xs">Tags</p>
            </p>
            <Tags
              param={org}
              customTags={customTags}
              org={orgid.id}
              dropdownref={dropdownref}
              tags={tags}
              setTags={setTags}
              tagsDetail={tagsDetail}
              setTagsDetail={setTagsDetail}
              modalref={modalref}
            />
          </div>
        </div>
        <AttributesRenderer
          attributesRef={customAttributeRef}
          attributes={Array.from(selectedAttributes)}
          metadata={metadata}
          setMetadata={setMetadata}
          template={template}
        />
        {template?.length > 0 && (
          <Dropdown>
            <DropdownTrigger>
              <Button size="sm" className="" variant="light">
                + Add Attributes
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              selectionMode="multiple"
              selectedKeys={selectedAttributes}
              onSelectionChange={setSelectedAttributes}
            >
              {template?.map((item) => (
                <DropdownItem key={item.name}>{item.name}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
        <Divider className="mt-4" />
        <Tabs aria-label="Options" className="mt-8" size="sm">
          <Tab key="docs 0" title="Docs">
            <div>
              <div>
                <div className="flex gap-10 text-xs ">
                  <p className=" text-xs text-center flex justify-center items-center">
                    PRD
                  </p>
                  <Button
                    onClick={() => setIsOpenPRD(true)}
                    size="sm"
                    className="bg-transparent text-center ml-4 flex justify-center items-center"
                  >
                    Select
                  </Button>
                </div>
                {prd.length > 0 &&
                  prd.map((item) => (
                    <div className="flex text-xs items-center" key={item.Title}>
                      <p className="mr-2">{item.Title}</p>
                      <Link
                        showAnchorIcon
                        href={
                          item.URL.startsWith("http")
                            ? item.URL
                            : `https://${item.URL}`
                        }
                        passHref
                      ></Link>
                      <button
                        onClick={() => handleRemovePRD(item.Title)}
                        className="ml-2 text-red-500 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
              </div>
              <Modal
                ref={modalref}
                isOpen={isOpenPRD}
                onClose={() => {
                  setIsOpenPRD(false);
                  resetModalErrors("PRD");
                }}
              >
                <ModalContent>
                  <ModalHeader className="flex flex-col gap-1">
                    Add PRD
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <p className="">Title</p>
                        <Input
                          radius="sm"
                          classNames={inputClassNames}
                          variant="bordered"
                          value={titlePRD}
                          onChange={(e) => setTitlePRD(e.target.value)}
                          placeholder="Enter title"
                        />
                      </div>
                      {errors.titlePRD && (
                        <p className="text-red-500 text-xs">
                          {errors.titlePRD}
                        </p>
                      )}
                      <div className="flex flex-col gap-2">
                        <p className="">URL</p>
                        <Input
                          radius="sm"
                          classNames={inputClassNames}
                          variant="bordered"
                          value={urlPRD}
                          onChange={(e) => setUrlPRD(e.target.value)}
                          placeholder="Enter URL"
                        />
                      </div>
                      {errors.urlPRD && (
                        <p className="text-red-500 text-xs">{errors.urlPRD}</p>
                      )}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onClick={() => setIsOpenPRD(false)}
                      size="sm"
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        handleSubmitt();
                        handleAddItem("PRD");
                      }}
                    >
                      Add PRD
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </div>
            <div>
              <div>
                <div className="flex gap-10 text-xs ">
                  <p className=" text-xs text-center flex justify-center items-center">
                    Design
                  </p>
                  <Button
                    onClick={() => setIsOpenDesign(true)}
                    size="sm"
                    className="bg-transparent  text-center flex justify-center items-center"
                  >
                    Select
                  </Button>
                </div>
                {design.length > 0 &&
                  design.map((item) => (
                    <div className="flex text-xs items-center" key={item.Title}>
                      <p className="mr-2">{item.Title}</p>
                      <Link
                        showAnchorIcon
                        href={
                          item.URL.startsWith("http")
                            ? item.URL
                            : `https://${item.URL}`
                        }
                        passHref
                      ></Link>
                      <button
                        onClick={() => handleRemoveDesign(item.Title)}
                        className="ml-2 text-red-500 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
              </div>
              <Modal
                ref={modalref}
                isOpen={isOpenDesign}
                onClose={() => {
                  setIsOpenDesign(false);
                  resetModalErrors("Design");
                }}
              >
                <ModalContent>
                  <ModalHeader className="flex flex-col gap-1">
                    Add Design
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <p className="">Title</p>
                        <Input
                          radius="sm"
                          classNames={inputClassNames}
                          variant="bordered"
                          value={titleDesign}
                          onChange={(e) => setTitleDesign(e.target.value)}
                          placeholder="Enter title"
                        />
                      </div>
                      {errors.titleDesign && (
                        <p className="text-red-500 text-xs">
                          {errors.titleDesign}
                        </p>
                      )}
                      <div className="flex flex-col gap-2">
                        <p className="">URL</p>
                        <Input
                          radius="sm"
                          classNames={inputClassNames}
                          variant="bordered"
                          value={urlDesign}
                          onChange={(e) => setUrlDesign(e.target.value)}
                          placeholder="Enter URL"
                        />
                      </div>
                      {errors.urlDesign && (
                        <p className="text-red-500 text-xs">
                          {errors.urlDesign}
                        </p>
                      )}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onClick={() => setIsOpenDesign(false)}
                      size="sm"
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        handleSubmitt();
                        handleAddItem("Design");
                      }}
                    >
                      Add Design
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </div>
            <div>
              <div>
                <div className="flex gap-10 text-xs ">
                  <p className=" text-xs text-center flex justify-center items-center">
                    Other
                  </p>
                  <Button
                    onClick={() => setIsOpenOther(true)}
                    size="sm"
                    className="bg-transparent text-center ml-2  flex justify-center items-center"
                  >
                    Select
                  </Button>
                </div>
                {other.length > 0 &&
                  other.map((item) => (
                    <div className="flex text-xs items-center" key={item.Title}>
                      <p className="mr-2">{item.Title}</p>
                      <Link
                        showAnchorIcon
                        href={
                          item.URL.startsWith("http")
                            ? item.URL
                            : `https://${item.URL}`
                        }
                        passHref
                      ></Link>
                      <button
                        onClick={() => handleRemoveOther(item.Title)}
                        className="ml-2 text-red-500 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
              </div>
              <Modal
                ref={modalref}
                isOpen={isOpenOther}
                onClose={() => {
                  setIsOpenOther(false);
                  resetModalErrors("Other");
                }}
              >
                <ModalContent>
                  <ModalHeader className="flex flex-col gap-1">
                    Add Other
                  </ModalHeader>
                  <ModalBody>
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <p className="">Title</p>
                        <Input
                          radius="sm"
                          classNames={inputClassNames}
                          variant="bordered"
                          value={titleOther}
                          onChange={(e) => setTitleOther(e.target.value)}
                          placeholder="Enter title"
                        />
                      </div>
                      {errors.titleOther && (
                        <p className="text-red-500 text-xs">
                          {errors.titleOther}
                        </p>
                      )}
                      <div className="flex flex-col gap-2">
                        <p className="">URL</p>
                        <Input
                          radius="sm"
                          classNames={inputClassNames}
                          variant="bordered"
                          value={urlOther}
                          onChange={(e) => setUrlOther(e.target.value)}
                          placeholder="Enter URL"
                        />
                      </div>
                      {errors.urlOther && (
                        <p className="text-red-500 text-xs">
                          {errors.urlOther}
                        </p>
                      )}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onClick={() => setIsOpenOther(false)}
                      size="sm"
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        handleSubmitt();
                        handleAddItem("Other");
                      }}
                    >
                      Add Other
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </div>
          </Tab>
         
        </Tabs>
        <div className="flex w-full items-center justify-end" >
        <Button
          size="sm"
          disabled={
            name === "" ||
            descriptionContent === "" ||
            descriptionContent === "<p></p>" ||
            (Array.from(selectedKeyss)[0] === "Product"
              ? false
              : parentId === "")
          }
          onClick={handleSubmit}
          className={` ${
            name === "" ||
            descriptionContent === "" ||
            descriptionContent === "<p></p>" ||
            (Array.from(selectedKeyss)[0] === "Product"
              ? false
              : parentId === "") ||
            ownerInfo.id === ""
              ? ""
              : "bg-[rgb(51,51,245)] text-white"
          } mt-8`}
        >
          Create
        </Button>
        </div>
        
      </div>
    </div>
  );
};

export default CreateDrawer;
